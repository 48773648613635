import React from "react";

export default ({ className }) => <svg className={className} viewBox="0 0 24 24" fill="none">
  <g clip-path="url(#clip0)">
    <path
      fill="currentColor"
      d="M23.114 16.468l-5.081-2.18a1.47 1.47 0 0 0-1.711.418l-2.067
      2.526a16.406 16.406 0 0 1-7.482-7.481L9.3 7.684a1.47 1.47 0 0 0
      .417-1.71L7.537.886A1.469 1.469 0 0 0 5.86.04L1.134 1.13A1.456 1.456 0 0 0
      0 2.556c0 11.873 9.623 21.445 21.445 21.445a1.464 1.464 0 0 0
      1.43-1.14l1.087-4.72a1.462 1.462 0 0 0-.848-1.673zm-1.697 6.033C10.425
      22.487 1.514 13.58 1.505 2.589L6.173 1.51 8.33 6.545 4.917 9.334c2.269
      4.837 4.899 7.476 9.755 9.754l2.789-3.412 5.034 2.156-1.078 4.669z" />
  </g>
  <defs>
    <clipPath id="clip0">
      <path fill="#fff" d="M0 0h24v24H0z" />
    </clipPath>
</defs>
</svg>
