import styled from "styled-components";

const ModalBackground = () =>
  styled.div.attrs({
    className: "fixed flex top-0 left-0 w-screen h-screen",
  })`
    z-index: 93;
  `;

export default ModalBackground;
