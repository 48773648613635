import { useEffect } from "react";
import { useCallback } from "react";
import {
  updateMarketCommunitiesForm,
  updateShowMarketCommunitiesForm,
  useUiValue,
} from "stores/uiContext";

const useMarketCommunitiesForm = () => {
  const [
    { marketCommunitiesForm, showMarketCommunitiesForm },
    dispatch,
  ] = useUiValue();

  useEffect(() => {
    dispatch(updateMarketCommunitiesForm(marketCommunitiesForm));
    dispatch(updateShowMarketCommunitiesForm(showMarketCommunitiesForm));
  }, []);

  const updateMarketForms = useCallback(
    (marketCommunitiesForm) => {
      dispatch(updateMarketCommunitiesForm(marketCommunitiesForm));
    },
    [marketCommunitiesForm]
  );
  const updateShowMarketForms = useCallback(
    (flag) => {
      dispatch(updateShowMarketCommunitiesForm(flag));
    },
    [showMarketCommunitiesForm]
  );

  return {
    marketCommunitiesForm,
    showMarketCommunitiesForm,
    updateMarketForms,
    updateShowMarketForms,
  };
};
export default useMarketCommunitiesForm;
