import {
  SITECORE_FORMS_OPERATORS,
  SITECORE_FORMS_MATCH_TYPE,
  SITECORE_FORMS_ACTIONS,
} from "utils/enums";

const isAny = (matchType) => {
  return matchType === SITECORE_FORMS_MATCH_TYPE.ANY;
};
const isAll = (matchType) => {
  return matchType === SITECORE_FORMS_MATCH_TYPE.ALL;
};

export const validateConditions = (conditions, matchType, isModal = false) => {
  let flag = false;
  for (let i = 0; i < conditions.length; i++) {
    const value = conditions[i].value;
    const operatorId = conditions[i].operatorId;
    const fieldId = conditions[i].fieldId;

    if (typeof window !== "undefined" && document) {
      const fieldDOM = document.getElementById(
        isModal ? "Modal-" + fieldId : fieldId
      );
      const input =
        fieldDOM.querySelector("input") || fieldDOM.querySelector("textArea");
      const select = fieldDOM.querySelector("select");
      let fieldValue = "";

      if (input) {
        fieldValue = input.value;
      }
      if (select) {
        fieldValue = select.options[select.selectedIndex].value;
      }
      switch (operatorId) {
        case SITECORE_FORMS_OPERATORS.IS_EQUAL:
          if (
            (isAny(matchType) && fieldValue === value) ||
            (isAll(matchType) && fieldValue !== value)
          )
            flag = true;
          break;
        case SITECORE_FORMS_OPERATORS.IS_NOT_EQUAL:
          if (
            (isAny(matchType) && fieldValue !== value) ||
            (isAll(matchType) && fieldValue === value)
          )
            flag = true;
          break;
        default:
          break;
      }
      if (flag) break;
    }
  }
  return flag;
};

export const reviewActions = (actions, make, isModal = false) => {
  for (let i = 0; i < actions.length; i++) {
    const actionTypeId = actions[i].actionTypeId;
    const fieldId = actions[i].fieldId;

    if (typeof window !== "undefined" && document) {
      const fieldDOM = document.getElementById(
        isModal ? "Modal-" + fieldId : fieldId
      );
      const input =
        fieldDOM.querySelector("input") ||
        fieldDOM.querySelector("select") ||
        fieldDOM.querySelector("textArea");

      if (input) {
        switch (actionTypeId) {
          case SITECORE_FORMS_ACTIONS.DISABLED:
            fieldDOM.style.display = make ? "none" : "";
            input.disabled = make ? true : false;
            break;
          case SITECORE_FORMS_ACTIONS.ENABLE:
            fieldDOM.style.display = make ? "" : "none";
            input.disabled = make ? false : true;
            break;
          default:
            break;
        }
      }
    }
  }
};
