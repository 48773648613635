export default {
  HomePage: 0,
  MetroSearch: 1,
  CommunityPage: 2,
  MpcPage: 3,
  PlanPage: 4,
  QmiPage: 5,
  CommonPage: 6,
  CondoPlan: 7,
  PromoPage: 8,
  MarketPage: 9,
  ContactUs: 10,
  JanePage: 11,
  DesignStudio: 12,
  HomeBuying: 13,
  AboutUs: 14,
  SupportPage: 15,
  CondoPage: 16,
  MarketDesignStudio: 17,
};
