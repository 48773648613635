export const searchProductTypes = {
  QMI: "qmi",
  PLAN: "plan",
  COMMUNITY: "community",
};

export const SITECORE_FORMS_MATCH_TYPE = {
  ANY: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
  ALL: "{4E50C172-7EA6-4989-82C3-75F24F80EF72}",
}
export const SITECORE_FORMS_OPERATORS = {
  IS_EQUAL: "{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}",
  IS_NOT_EQUAL: "{49F47E77-E8C5-46F9-BF39-78D6B0D40B48}",
}
export const SITECORE_FORMS_ACTIONS = {
  DISABLED: "{C698C993-549E-486A-A09C-BB8D830DA958}",
  ENABLE: "{5744A87E-E32C-42CC-862F-96842A0202BB}",
}