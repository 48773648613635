import React from "react";
import i18n from "i18next";
import Helmet from "react-helmet";
import {
  isExperienceEditorActive,
  dataApi,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { dataFetcher } from "./dataFetcher";
import config from "./temp/config";
import Layout from "./Layout";
import NotFound from "./NotFound";
import { ContextProvider } from "./stores/sitecoreContext";
import { isValidCountry, getCountryAppCode } from "./utils/geolocation";

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static React routing isn't enough -
// we need to be able to load dynamic route data from Sitecore after the client side route changes.
// So react-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.

class RouteHandler extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notFound: true,
      defaultLanguage: config.defaultLanguage,
    };

    const routeData = this.extractRouteData();

    // route data from react-router - if route was resolved, it's not a 404
    if (props.route !== null) {
      this.state.notFound = false;
    }

    // if we have an initial SSR state, and that state doesn't have a valid route data,
    // then this is a 404 route.
    if (routeData && (!routeData.sitecore || !routeData.sitecore.route)) {
      this.state.notFound = true;
    }

    // if we have an SSR state, and that state has language data, set the current language
    // (this makes the language of content follow the Sitecore context language cookie)
    // note that a route-based language (i.e. /de-DE) will override this default; this is for home.
    if (
      routeData &&
      routeData.sitecore &&
      routeData.sitecore.context &&
      routeData.sitecore.context.language
    ) {
      this.state.defaultLanguage = routeData.sitecore.context.language;
    }

    // tell i18next to sync its current language with the route language
    this.updateLanguage();
  }

  componentDidMount() {
    const routeData = this.extractRouteData();

    // if no existing routeData is present (from SSR), get Layout Service fetching the route data
    if (!routeData || this.props.ssrRenderComplete) {
      this.updateRouteData();
    }
  }

  extractRouteData = () => {
    if (!this.props.sitecoreContext) return null;

    const { route, ...context } = this.props.sitecoreContext;

    return {
      sitecore: {
        route,
        context,
      },
    };
  };

  /**
   * Loads route data from Sitecore Layout Service into state.routeData
   */
  updateRouteData() {
    let sitecoreRoutePath = this.props.route.match.params.sitecoreRoute || "/";
    if (!sitecoreRoutePath.startsWith("/")) {
      sitecoreRoutePath = `/${sitecoreRoutePath}`;
    }
    let language = this.state.defaultLanguage;
    let savedCountry;
    if (typeof window !== "undefined" && localStorage) {
      savedCountry = localStorage.getItem("userCountry");
    }
    if (this.props.sitecoreContext) {
      if (!savedCountry && this.props.sitecoreContext.geolocation) {
        const countryCode = this.props.sitecoreContext.geolocation.value.country;
        if (isValidCountry(countryCode)) {
          const countryAppCode = getCountryAppCode(countryCode);
          if (typeof window !== "undefined" && localStorage) {
            savedCountry = localStorage.setItem("userCountry", countryAppCode);
          }
        }
        language = savedCountry === "CAN" ? "en-CA" : "en";
      } else {
        language = this.props.sitecoreContext.language;
      }
    } else {
      language = savedCountry === "CAN" ? "en-CA" : "en";
    }

    // get the route data for the new route
    getRouteData(sitecoreRoutePath, language).then((routeData) => {
      if (
        routeData !== null &&
        routeData.sitecore &&
        routeData.sitecore.route
      ) {
        // set the sitecore context data and push the new route
        this.props.updateSitecoreContext({
          route: routeData.sitecore.route,
          itemId: routeData.sitecore.route.itemId,
          ...routeData.sitecore.context,
        });
        this.setState({ notFound: false });
      } else {
        this.setState({ notFound: true }, () => {
          const context =
            routeData && routeData.sitecore ? routeData.sitecore.context : null;
          this.props.updateSitecoreContext(context);
        });
      }
    });
  }

  /**
   * Updates the current app language to match the route data.
   */
  updateLanguage() {
    const newLanguage =
      this.props.route.match.params.lang || this.state.defaultLanguage;
    if (i18n.language !== newLanguage) {
      this.languageIsChanging = true;
      i18n.changeLanguage(newLanguage);
    }
  }

  componentDidUpdate(previousProps) {
    const existingRoute = previousProps.route.match.url;
    const newRoute = this.props.route.match.url;
    const previousLanguage =
      previousProps.sitecoreContext && previousProps.sitecoreContext.language;
    const newLanguage =
      this.props.sitecoreContext && this.props.sitecoreContext.language;

    // don't change state (refetch route data) if the route has not changed
    if (!previousLanguage || !newLanguage) return;
    if (existingRoute === newRoute && previousLanguage === newLanguage) {
      return;
    }

    // if in experience editor - force reload instead of route data update
    // avoids confusing Sitecore's editing JS
    if (isExperienceEditorActive()) {
      window.location.assign(newRoute);
      return;
    }

    this.updateLanguage();
    this.updateRouteData();
  }

  render() {
    const { notFound } = this.state;
    const routeData = this.extractRouteData();

    // no route data for the current route in Sitecore - show not found component.
    // Note: this is client-side only 404 handling. Server-side 404 handling is the responsibility
    // of the server being used (i.e. node-headless-ssr-proxy and Sitecore intergrated rendering know how to send 404 status codes).
    if (notFound && routeData) {
      return (
        <div>
          <Helmet>
            <title>{i18n.t("Page not found asf")}</title>
          </Helmet>
          <NotFound
            context={routeData.sitecore && routeData.sitecore.context}
          />
        </div>
      );
    }

    // Don't render anything if the route data or dictionary data is not fully loaded yet.
    // This is a good place for a "Loading" component, if one is needed.
    if (!routeData /*|| this.languageIsChanging*/) {
      return null;
    }

    const contextValue = {
      ...routeData.sitecore.context,
      templateId: routeData.sitecore.route.templateId,
    };
    // Render the app's root structural layout
    return (
      <ContextProvider value={contextValue}>
        <Layout route={routeData.sitecore.route} />
      </ContextProvider>
    );
  }
}

/**
 * Gets route data from Sitecore. This data is used to construct the component layout for a JSS route.
 * @param {string} route Route path to get data for (e.g. /about)
 * @param {string} language Language to get route data in (content language, e.g. 'en')
 */
function getRouteData(route, language) {
  const fetchOptions = {
    layoutServiceConfig: { host: config.sitecoreApiHost },
    querystringParams: { sc_lang: language, sc_apikey: config.sitecoreApiKey },
    fetcher: dataFetcher,
  };

  return dataApi.fetchRouteData(route, fetchOptions).catch((error) => {
    if (
      error.response &&
      error.response.status === 404 &&
      error.response.data
    ) {
      return error.response.data;
    }

    console.error("Route data fetch error", error, error.response);

    return null;
  });
}

export default withSitecoreContext({ updatable: true })(RouteHandler);
