import React from "react";
import cx from "classnames";
import styled from "styled-components";
import PropTypes from "prop-types";
import { RichText } from "@sitecore-jss/sitecore-jss-react";

const H1 = styled.h1`
  ${({ shadowed }) => shadowed && "text-shadow: 0 3px 10px rgba(0, 0, 0 );"}
  ${({ newSchemaColor, color }) => newSchemaColor && `color: ${color};`}
`;

const Heading = ({
  children,
  color = "blue",
  className,
  shadowed = false,
  large = false,
  as = "h1",
  ...rest
}) => (
  <H1
    as={as}
    {...rest}
    className={cx(
      "font-bold leading-tiny font-trade-gothic-20 mb-6 md:mb-10-mattamy",
      {
        "text-4xl": !large,
        "text-6xl": large,
        "text-white": color === "white",
        "text-mattamy-blue": color === "blue",
        "text-black": color === "black",
      },
      className
    )}
    newSchemaColor={color.includes("#")}
    color={color}
    shadowed={shadowed}
  >
    <RichText tag="span" className="richtext" field={{ value: children }} />
  </H1>
);

Heading.propTypes = {
  as: PropTypes.string,
  color: PropTypes.oneOf(["white", "blue"]),
  className: PropTypes.string,
  shadowed: PropTypes.bool,
  large: PropTypes.bool,
};

export default Heading;
