import React from "react";
import PropTypes from "prop-types";
import getEmbedUrl from "utils/getEmbedUrl";
import { isValidUrl } from "utils/validateUrlSrc";

const ImageVideo = ({
  src,
  alt,
  displayOption,
  videoType,
  autoplay, //handleAutoPlay Mode
  removeTabIndex, // handleTabindex
}) => (
  <div
    tabIndex={!removeTabIndex ? 0 : null}
    className="w-full h-full rounded overflow-hidden"
  >
    {displayOption === "Image" && isValidUrl(src) && (
      <img
        src={src}
        alt={alt}
        aria-label={"image with " + alt}
        className="object-cover w-full h-full"
      />
    )}
    {displayOption === "Video" && videoType === "external" && (
      <iframe
        className="object-cover w-full h-full bg-white"
        src={getEmbedUrl(src, autoplay)}
        title={alt}
      />
    )}
    {displayOption === "Video" &&
      videoType === "internal" &&
      isValidUrl(src) && (
        <video
          src={src}
          alt={alt}
          className="object-cover w-full h-full bg-white"
          autoPlay={autoplay}
          muted={autoplay}
          loop
          controls
        />
      )}
  </div>
);

ImageVideo.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  displayOption: PropTypes.string,
};

export default ImageVideo;
