import React from "react";
import cx from "classnames";
import styled from "styled-components";
import PropTypes from "prop-types";
import OpenNewTab from "components/icons/OpenNewTab";

const StyledButton = styled.button`
  border-radius: 0.625rem;
  ${({ showNewSchemaColor, textColor, outlined }) =>
    showNewSchemaColor && outlined && `border-color: ${textColor};`}
  ${({ showNewSchemaColor, textColor, outlined }) =>
    showNewSchemaColor && outlined && `color: ${textColor};`}
  
  ${({ showNewSchemaColor, color, filled }) =>
    showNewSchemaColor && filled && `background-color: ${color};`}
  ${({ showNewSchemaColor, textColor, filled }) =>
    showNewSchemaColor && filled && `color: ${textColor};`}
`;

const LinkWrapper = ({
  children,
  href,
  target,
  fullWidthMobile,
  downloadable,
  downloadLabel,
  linkWrapperDescription,
  onClick,
  linkRef,
  ...rest
}) => {
  if (href && !downloadable) {
    return (
      <a
        href={href}
        role="link"
        ref={linkRef}
        target={target || "_self"}
        className={cx({ "w-full": fullWidthMobile })}
        aria-label={
          linkWrapperDescription +
          ". " +
          (target === "_blank" ? "Opens in new tab" : "")
        }
        {...rest}
        onClick={(e) => {
          typeof onClick === "function" && !href && onClick(e);
        }}
      >
        {children}
      </a>
    );
  }

  if (href && downloadable) {
    return (
      <a
        href={href}
        className={cx({ "w-full": fullWidthMobile })}
        download={downloadLabel || ""}
        onClick={(e) => {
          typeof onClick === "function" && !href && onClick(e);
        }}
        ref={linkRef}
        aria-label={linkWrapperDescription}
      >
        {children}
      </a>
    );
  }

  if (!href) {
    return children;
  }
};

const Button = ({
  children,
  className,
  variant = "filled",
  color = "action-blue",
  textColor = "action-blue",
  onClick,
  href,
  target,
  fullWidthMobile = false,
  downloadable = false,
  downloadLabel = "",
  disabled,
  disableHover = false,
  buttonDescription = "",
  linkWrapperDescription = "",
  height = false,
  linkRole = "link",
  buttonRef,
  linkRef,
  showNewSchemaColor = false,
  ...rest
}) => {
  //console.log({ buttonDescription, showNewSchemaColor, color, textColor, variant });
  return (
    <LinkWrapper
      href={href}
      target={target}
      fullWidthMobile={fullWidthMobile}
      downloadable={downloadable}
      downloadLabel={downloadLabel}
      linkWrapperDescription={linkWrapperDescription}
      role={linkRole}
      onClick={onClick}
      linkRef={linkRef}
    >
      <StyledButton
        aria-hidden={href || downloadable ? true : false}
        tabIndex={href || downloadable ? "-1" : "0"}
        showNewSchemaColor={showNewSchemaColor}
        color={color}
        textColor={textColor}
        outlined={variant === "outlined"}
        filled={variant === "filled"}
        className={cx(
          "uppercase font-trade-gothic-20 py-4 px-8 tracking-wide w-full md:w-auto",
          {
            "h-60-mattamy": !height,
            "text-white bg-action-blue hover:bg-mattamy-blue":
              variant === "filled" && color === "action-blue",
            "text-white bg-mattamy-blue":
              variant === "filled" && color === "mattamy-blue",
            "text-mattamy-blue bg-white border-1-5 border-white":
              variant === "filled" &&
              color === "white" &&
              textColor === "mattamy-blue",
            "text-action-blue bg-white":
              variant === "filled" &&
              color === "white" &&
              textColor === "action-blue",
            "text-action-blue border-1-5 bg-transparent border-action-blue hover:bg-action-blue hover:text-white":
              variant === "outlined" &&
              color === "action-blue" &&
              textColor === "action-blue",
            "text-mattamy-blue border-1-5 bg-transparent border-action-blue":
              variant === "outlined" &&
              color === "mattamy-blue" &&
              textColor === "mattamy-blue",
            "text-white border-1-5 bg-transparent border-white":
              variant === "outlined" && color === "white",
            "hover:text-mattamy-blue hover:bg-white":
              variant === "outlined" &&
              color === "white" &&
              !disabled &&
              disableHover,
            "text-mattamy-blue border-1-5 bg-transparent border-mattamy-blue hover:text-white hover:bg-mattamy-blue":
              variant === "outlined" && color === "mattamy-blue",
            "cursor-pointer": !disabled,
            "cursor-default": disabled,
            "border-1-5 bg-transparent":
              showNewSchemaColor && variant === "outlined",
          },
          className
        )}
        disabled={disabled}
        onClick={(e) => {
          typeof onClick === "function" && !href && onClick(e);
        }}
        aria-label={
          buttonDescription + " ." + target === "_blank" && href
            ? "Opens in new tab"
            : ""
        }
        ref={buttonRef}
        {...rest}
      >
        <div className="mt-1 flex justify-center w-auto">
          {children}
          {target === "_blank" && href && (
            <OpenNewTab className="mb-1 ml-1 w-5" />
          )}
        </div>
      </StyledButton>
    </LinkWrapper>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(["outlined", "filled"]),
  color: PropTypes.oneOf(["action-blue", "mattamy-blue"]),
  textColor: PropTypes.oneOf(["action-blue", "mattamy-blue", "white"]),
  onClick: PropTypes.func,
  href: PropTypes.string,
  target: PropTypes.string,
  fullWidthMobile: PropTypes.bool,
};

export default Button;
