import { createContext, useContext } from "react";

export const countries = ["USA", "CAN"];

// Context
export const CountryContext = createContext(undefined);

// Initial State
export const initialState = { country: undefined };

// Reducer
export const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SWITCH_COUNTRY":
      return {
        country: state.country === countries[0] ? countries[1] : countries[0],
      };
    case "SELECT_COUNTRY":
      return {
        country: action.payload,
      };
    default:
      throw new Error("Invalid Action Type");
  }
};

// Actions
export const switchCountry = () => ({ type: "SWITCH_COUNTRY" });
export const selectCountry = (country) => ({
  type: "SELECT_COUNTRY",
  payload: country,
});

// Hooks
export const useCountryValue = () => useContext(CountryContext);
