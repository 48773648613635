import { useState, useEffect } from "react";
import throttle from "lodash.throttle";

const getDeviceConfig = (width) => {
  if (width < 640) {
    return "xs";
  } else if (width >= 640 && width < 768) {
    return "sm";
  } else if (width >= 768 && width < 1024) {
    return "md";
  } else if (width >= 1024 && width < 1280) {
    return "lg";
  } else if (width >= 1280 && width < 1920) {
    return "xl";
  } else {
    return "2xl";
  }
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() =>
    getDeviceConfig(typeof window !== "undefined" && window.innerWidth)
  );

  useEffect(() => {
    const calcInnerWidth = throttle(function () {
      setBrkPnt(
        getDeviceConfig(typeof window !== "undefined" && window.innerWidth)
      );
    }, 200);
    typeof window !== "undefined" &&
      window.addEventListener("resize", calcInnerWidth);
    return () =>
      typeof window !== "undefined" &&
      window.removeEventListener("resize", calcInnerWidth);
  }, []);

  return brkPnt;
};
export default useBreakpoint;
