import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import tw from "tailwind.macro";
import ChevronDownIcon from "components/icons/ChevronDown";
import { useSitecoreContext } from "stores/sitecoreContext";
import tailwindConfig from "tailwind.config.js";
import resolveConfig from "tailwindcss/resolveConfig";
import { FieldTypes } from "@sitecore-jss/sitecore-jss-react-forms";
import breakpoints from "utils/breakpoints";
import cx from "classnames";
import {
  validateConditions,
  reviewActions,
} from "utils/sitecoreConditionFunctions";
import { validateEmail } from "utils/validateEmail";

const fullConfig = resolveConfig(tailwindConfig);

const ValidationBubble = styled.div`
  background: #fff;
  margin: 0 auto;
  padding: 6px;
  text-align: center;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 2px;
  border-radius: 5px;
  border: 1px solid #9b9b9b;
  margin-left: 8rem;
  margin-top: 7.25rem;
  z-index: 99;
  color: #0b2c71;
  font-size: 1rem;

  &:after {
    content: "";
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 2px;
    -moz-transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    top: -6px;
    left: 20px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent #fff #fff transparent;
  }
`;

const InputContainer = styled.div.attrs(({ className }) => ({
  className: `w-full m-auto flex justify-center mb-4 relative ${
    className || ""
  }`,
}))`

  input[type="email"] {
    text-transform: lowercase;
  }
  padding: 0 0.175rem;

  ${({ fieldTypeItemId }) =>
    fieldTypeItemId === FieldTypes.MultipleLineText && "margin-bottom: 2.5rem;"}

  & label {
    display: none;
  }

  & input,
  & textarea {
    outline: none;
  }

  & *:not(button):not(svg):not(div):not(option) {
    ${tw`border font-graphie rounded-none font-semibold
      px-4 py-2 w-full rounded bg-white`}
    line-height: 1.75rem;
    height: 4.375rem;

    ${({ empty }) =>
      empty ? tw`bg-transparent font-normal` : tw`text-action-blue`}

    ${({ empty, focused, error }) =>
      !empty && !focused && !error && tw`border-none`}

    ${({ error }) =>
      error ? tw`border-field-error bg-white` : tw`border-action-blue`}
  }

  & select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    outline: none;
    ${({ error }) =>
      `& > option {
        color: ${
          !error
            ? fullConfig.theme.colors["action-blue"]
            : fullConfig.theme.colors["field-error"]
        }
      }`}
    ${({ error }) => (error ? tw`text-field-error` : tw`text-action-blue`)}
  }

  & textarea {
    height: 8.125rem !important;
  }
`;

const CheckboxContainer = styled.div`
  & label {
    ${tw`flex items-center`}
  }
  & input {
    appearance: none;
    width: ${fullConfig.theme.width["5"]};
    min-width: ${fullConfig.theme.width["5"]};
    height: ${fullConfig.theme.height["5"]};
    min-height: ${fullConfig.theme.height["5"]};
    border: 1px solid ${fullConfig.theme.colors["action-blue"]};
    border-radius: 100%;
    margin-right: ${fullConfig.theme.margin["3"]};
    align-self: flex-start;
    margin-top: 0.2rem;

    &:checked {
      background: ${fullConfig.theme.colors["action-blue"]};
    }
  }
`;

const ButtonContainer = styled.div`
  button {
    color: ${fullConfig.theme.colors["white"]};
    font-family: ${fullConfig.theme.fontFamily["trade-gothic-20"][0]};
    letter-spacing: 0.6px;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    height: 3.125rem;
    background-color: ${fullConfig.theme.colors["action-blue"]};
    padding-top: 2px;

    &:hover {
      background-color: ${fullConfig.theme.colors["mattamy-blue"]};
    }

    @media ${breakpoints.md} {
      width: 12.5rem;
    }
  }
`;

const IconContainer = styled.div.attrs(({ className }) => ({
  className: `absolute w-6 top-0 bottom-0 m-auto flex items-center z-10 ${className}`,
}))`
  pointer-events: none;
  right: 1rem;
`;

const isFieldByName = ({ field }, name) => {
  return field.model && field.model.name === name;
};

const isEmpty = (value) => {
  if (!value) {
    return true;
  }
  if (Array.isArray(value) && (!value.length || !value[0])) {
    return true;
  }
  return false;
};

const getnextInvalidElement = () => {
  const requiredInputs = document.querySelectorAll(
    "input[type=text]:required,input[type=email]:required,textarea:required,select:required"
  );
  let focusIndex = -1;
  for (let i = 0; i < requiredInputs.length; i++) {
    if (
      requiredInputs[i].value === "" ||
      (requiredInputs[i].type === "email" &&
        !validateEmail(requiredInputs[i].value))
    ) {
      focusIndex = focusIndex === -1 ? i : focusIndex;
      requiredInputs[
        i
      ].style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
      if (requiredInputs[i].tagName === "SELECT") {
        requiredInputs[
          i
        ].style.color = `${fullConfig.theme.colors["field-error"]}`;
        requiredInputs[
          i
        ].parentElement.parentElement.lastElementChild.children[0].style.color = `${fullConfig.theme.colors["field-error"]}`;
      }
      requiredInputs[i].classList.remove("placeholder_mattamy_blue");
      requiredInputs[i].classList.add("placeholder_error");
    }
  }
  if (requiredInputs.length > 0 && focusIndex > -1) {
    return requiredInputs[focusIndex];
  }
  return null;
};

const OutlinedField = (props = {}) => {
  const { ContextID = {} } = useSitecoreContext() || {};
  const [focused, setFocused] = useState();
  const elementRef = useRef(null);

  const title = props.field && props.field.model && props.field.model.title;
  const name = props.field && props.field.model && props.field.model.name;
  const fieldTypeItemId = props.field.model.fieldTypeItemId;
  const isFieldRequired = props.field.model.required;
  const fieldConditionKey = props.field.model.conditionSettings.fieldKey;
  const hasConditions =
    props.field.model.conditionSettings &&
    props.field.model.conditionSettings.fieldConditions.length > 0;

  useEffect(() => {
    if (elementRef) {
      const element = elementRef.current;
      if (fieldTypeItemId === FieldTypes.DropdownList) {
        const select = element.querySelector("select");
        const emptyOption = select && select.options ? select.options[0] : null;
        if (emptyOption && !emptyOption.value) {
          emptyOption.label = "";
          emptyOption.text = "";
          emptyOption.disabled = true;
          emptyOption.setAttribute("value", "");
        }
      }

      if (fieldTypeItemId === FieldTypes.Button) {
        const submitButton = element.querySelector("button");
        if (submitButton) {
          submitButton.addEventListener("click", function (event) {
            setTimeout(() => {
              const nextFocusInput = getnextInvalidElement();
              if (nextFocusInput) {
                nextFocusInput.focus({ preventScroll: false });
              }
            }, 100);
          });
        }
      }

      const input =
        element &&
        (element.querySelector("input") ||
          element.querySelector("select") ||
          element.querySelector("textarea"));
      if (input) {
        if (name === "ContextID") {
          let labelInput =
            input.parentElement && input.parentElement.firstElementChild;
          if (labelInput) labelInput.innerHTML = name;
        }
        input.setAttribute("aria-label", title || name);
        input.setAttribute("placeholder", "");
        input.classList.add("placeholder_mattamy_blue");
        if (isFieldRequired) {
          input.setAttribute("aria-label", "This Field is Required");
          input.setAttribute("required", true);
          if (input.type === "email") {
            const re = `[a-z0-9._%+-]+[a-z0-9]@[a-z0-9.-]+\.[a-z]{2,4}$`;
            input.setAttribute("pattern", re);
          }
          input.addEventListener("invalid", function (event) {
            event.preventDefault();
            let validationBubble = this.parentElement.lastElementChild;
            if (this.value === "") {
              validationBubble.classList.remove("hidden");
              event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
              event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
              event.target.classList.add("placeholder_error");
              event.target.classList.remove("placeholder_mattamy_blue");
            } else {
              if (this.type === "email" && !validateEmail(this.value)) {
                validationBubble.classList.remove("hidden");
                validationBubble.textContent = "Invalid email address";
                event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                event.target.classList.add("placeholder_error");
                event.target.classList.remove("placeholder_mattamy_blue");
              } else {
                validationBubble.classList.add("hidden");
              }
            }
          });

          input.addEventListener("change", (event) => {
            let validationBubble = event.target.parentElement.lastElementChild;
            if (event.target.value !== "") {
              event.target.style.border = `2px solid ${fullConfig.theme.colors["mattamy-blue"]}`;
              if (event.target.tagName === "SELECT") {
                event.target.style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
                event.target.parentElement.parentElement.lastElementChild.children[0].style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
              }
              event.target.classList.remove("placeholder_error");
              event.target.classList.add("placeholder_mattamy_blue");
              if (
                event.target.type === "email" &&
                !validateEmail(event.target.value)
              ) {
                validationBubble.textContent = "Invalid email address";
                validationBubble.classList.remove("hidden");
                event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                event.target.classList.add("placeholder_error");
                event.target.classList.remove("placeholder_mattamy_blue");
              } else {
                validationBubble.classList.add("hidden");
              }
            } else {
              validationBubble.classList.remove("hidden");
              event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
              event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
              event.target.classList.add("placeholder_error");
              event.target.classList.remove("placeholder_mattamy_blue");
            }
          });
        }
      }
    }

    if (isFieldByName(props, "ContextID")) {
      props.onChange(props.field.valueField.name, ContextID.value, true, null);
    }
  }, [elementRef]);

  useEffect(() => {
    if (hasConditions) {
      const conditions =
        props.field.model.conditionSettings.fieldConditions[0].conditions;
      const actions =
        props.field.model.conditionSettings.fieldConditions[0].actions;
      const matchType =
        props.field.model.conditionSettings.fieldConditions[0].matchTypeId;

      let flag = validateConditions(conditions, matchType);
      reviewActions(actions, flag);
    }
  });

  if (isFieldByName(props, "disclaimer")) {
    return <p className="disclaimer">{props.field.model.text}</p>;
  }

  if (
    isFieldByName(props, "elqFormName") ||
    isFieldByName(props, "elqPromoCode") ||
    isFieldByName(props, "title") ||
    isFieldByName(props, "subtitle") ||
    isFieldByName(props, "ReCaptcha") ||
    isFieldByName(props, "headline") ||
    isFieldByName(props, "description")
  ) {
    return null;
  }

  if (fieldTypeItemId === FieldTypes.Checkbox) {
    return (
      <CheckboxContainer
        id={fieldConditionKey}
        ref={elementRef}
        className="w-full text-action-blue my-2 flex content-start"
      >
        {props.children}
      </CheckboxContainer>
    );
  }

  if (fieldTypeItemId === FieldTypes.Button) {
    return (
      <ButtonContainer
        className="w-full flex justify-end mt-8 md:mt-2"
        ref={elementRef}
      >
        {props.children}
      </ButtonContainer>
    );
  }

  return (
    <InputContainer
      className={props.field.model.cssClass}
      focused={focused}
      empty={isEmpty(props.value)}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      error={props.errors && props.errors[0]}
      title={title}
      ref={elementRef}
      isDate={isFieldByName(props, "date")}
      fieldTypeItemId={fieldTypeItemId}
      id={fieldConditionKey}
    >
      <div className="flex flex-col w-full">
        <div className="text-action-blue my-2 pl-4">
          {fieldTypeItemId === FieldTypes.DropdownList ? "" : title}
        </div>
        {props.children}
        <ValidationBubble className="hidden">
          {"This field is required"}
        </ValidationBubble>
      </div>

      {props.field.model.items && (
        <IconContainer className="-mr-2 mt-4">
          <ChevronDownIcon
            className={cx("w-4", {
              "text-mattamy-gray": !isEmpty(props.value),
              "text-action-blue": isEmpty(props.value),
            })}
          />
        </IconContainer>
      )}
    </InputContainer>
  );
};

export default OutlinedField;
