import React from "react";
import cx from "classnames";
import styled from "styled-components";
import PropTypes from "prop-types";
import ChevronLeftIcon from "components/icons/ChevronLeft";
import SearchIcon from "components/icons/Search";
import CalendarIcon from "components/icons/Calendar";
import MapMarkerIcon from "components/icons/MapMarker";
import PhoneIcon from "components/icons/Phone";
import HeartIcon from "components/icons/Heart";
import ScheduleIcon from "components/icons/ScheduleIcon";

const Button = styled.button.attrs(({ tabIndex }) => ({
  tabIndex,
}))`
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0);
  ${({ shadowed }) => shadowed && `box-shadow: 0 0 20px 0 rgba(0,0,0,0.4);`}
  ${({ shadowedOnHover }) =>
    shadowedOnHover &&
    `
    &:hover {
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.4);
    }
  `}
`;

const arrowsClassName = "w-4 h-4 transform";
const sizeClassName = "w-8 h-8";

const getIcon = (iconName, color, small, idIcon = "", title) => {
  const customClassName = cx({
    "w-3 h-3": small,
  });

  switch (iconName) {
    case "arrow-down":
      return (
        <ChevronLeftIcon
          className={cx(arrowsClassName, customClassName, "w-4 h-4 -rotate-90")}
        />
      );
    case "arrow-up":
      return (
        <ChevronLeftIcon
          className={cx(arrowsClassName, customClassName, "w-4 h-4 rotate-90")}
        />
      );
    case "arrow-left":
      return (
        <ChevronLeftIcon
          className={cx(arrowsClassName, customClassName, "w-4 h-4")}
          title={title}
        />
      );
    case "arrow-right":
      return (
        <ChevronLeftIcon
          className={cx(arrowsClassName, customClassName, "w-4 h-4 rotate-180")}
          title={title}
        />
      );
    case "search":
      return <SearchIcon className={cx(sizeClassName, customClassName)} />;
    case "calendar":
      return <CalendarIcon className={cx("w-6")} />;
    case "mapmarker":
      return <MapMarkerIcon className={cx(customClassName, "w-6 h-6")} />;
    case "phone":
      return <PhoneIcon className={cx(customClassName, "w-6 h-6")} />;
    case "heart":
      return <HeartIcon className={cx(customClassName, "w-6 h-6")} />;
    case "schedule":
      return <ScheduleIcon className={cx(customClassName, "w-10 h-10")} />;
    default:
      return null;
  }
};

const IconButton = ({
  icon,
  circled,
  className,
  color,
  iconColor = "",
  hoverState,
  shadowed,
  shadowedOnHover,
  small,
  onClick,
  size,
  idIcon = "",
  buttonLabel = "",
  title = "",
  ref,
  ...rest
}) => (
  <Button
    {...rest}
    onClick={onClick}
    className={cx(
      "rounded-full flex justify-center items-center",
      {
        "bg-white text-action-blue":
          circled && color === "white" && iconColor === "",
        "bg-white text-black":
          circled && color === "white" && iconColor === "black",
        "bg-action-blue text-white": circled && color === "blue",
        "bg-mattamy-blue text-white": circled && color === "mattamy-blue",
        "bg-black text-white": circled && color === "black",
        "bg-aqua text-white": circled && color === "aqua",
        "w-16 h-16": !small && !size,
        "w-10 h-10": small,
        "hover:bg-mattamy-blue hover:text-white":
          hoverState && color === "white",
        "hover:bg-mattamy-blue-dark": hoverState && color === "blue",
      },
      className
    )}
    shadowed={shadowed}
    shadowedOnHover={shadowedOnHover}
    aria-label={buttonLabel}
    ref={ref}
  >
    {getIcon(icon, color, small, idIcon, title)}
  </Button>
);

IconButton.propTypes = {
  icon: PropTypes.oneOf([
    "arrow-down",
    "arrow-up",
    "arrow-left",
    "arrow-right",
    "calendar",
    "mapmarker",
    "phone",
  ]).isRequired,
  circled: PropTypes.bool,
  shadowed: PropTypes.bool,
  shadowedOnHover: PropTypes.bool,
  className: PropTypes.string,
  small: PropTypes.bool,
  size: PropTypes.bool,
};

export default IconButton;
