import { ytRegExp, vimeoRegExp } from "./regex";

const YOUTUBE_BASE_URL = "https://www.youtube.com/embed/";
const VIMEO_BASE_URL = "https://player.vimeo.com/video/";

const getEmbedUrl = (urlt, autoplay, enablejsapi = true) => {
  const url = urlt.replace(/\s/g, "");
  if (!url || typeof url !== "string") {
    return false;
  }
  const ytMatch = url.match(ytRegExp);

  if (ytMatch) {
    const id = ytMatch && ytMatch[7].length === 11 ? ytMatch[7] : false;

    return id
      ? `${YOUTUBE_BASE_URL}${id}?muted=${autoplay ? 1 : 0}&autoplay=${
          autoplay ? 1 : 0
        }" : ""}${enablejsapi ? "&enablejsapi=1&fs=1" : ""}`
      : false;
  }

  const vimeoMatch = url.match(vimeoRegExp);

  if (vimeoMatch) {
    const id = vimeoMatch[3];
    return id
      ? `${VIMEO_BASE_URL}${id}?muted=${autoplay ? 1 : 0}&autoplay=${
          autoplay ? 1 : 0
        }`
      : false;
  }

  return false;
};

export default getEmbedUrl;
