export function validateZipCode(zipCode, country) {
  const re = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/;
  const reUS = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  const reCAN = /^([a-zA-Z][0-9][a-zA-Z])\s*([0-9][a-zA-Z][0-9])$/;
  if (reUS.test(zipCode)) {
    return true;
  }
  if (reCAN.test(zipCode)) {
    return true;
  }

  return false;

  if (country === "USA") {
    if (reUS.test(zipCode)) {
      return true;
    }
    return false;
  }
  if (country === "CAN") {
    if (reCAN.test(zipCode)) {
      return true;
    }
    return false;
  }
  if (re.test(zipCode)) {
    return true;
  }
  return false;
}
