import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

const Input = ({ children, className, placeholder, ...rest }) => (
  <input
    {...rest}
    type="text"
    placeholder={placeholder}
    className={cx(
      "font-graphie text-mattamy-gray bg-white h-60-mattamy px-5 md:px-10",
      className
    )}
  >
    {children}
  </input>
);

Input.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export default Input;
