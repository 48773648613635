import React from "react";
import PropTypes from "prop-types";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import cx from "classnames";
import tw from "tailwind.macro";
import styled from "styled-components";

const StyledP = styled.p`
  ${({ shadowed }) => shadowed && "text-shadow: 0 3px 10px rgba(0, 0, 0 );"}
  ${({ newSchemaColor, color }) => newSchemaColor && `color: ${color};`}
  & ul {
    ${tw`list-disc`}
    padding-left: 1.125rem;
  }
`;

const Copy = ({
  children,
  className,
  shadowed = false,
  color = "blue",
  ...rest
}) => (
  <StyledP
    tabindex="-1"
    {...rest}
    className={cx(
      "text-normal mb-10-mattamy font-graphie",
      {
        "text-white": color === "white",
        "text-mattamy-blue": color === "blue",
        "text-black": color === "black",
        "text-mattamy-search-gray": color === "gray",
        "text-mattamy-gray-font": color === "grayonwhite",
      },
      className
    )}
    shadowed={shadowed}
    newSchemaColor={color.includes("#")}
    color={color}
  >
    <RichText
      tabIndex="-1"
      tag="span"
      className="richtext"
      field={{ value: children }}
    />
  </StyledP>
);

Copy.propTypes = {
  color: PropTypes.oneOf(["white", "blue"]),
  className: PropTypes.string,
};

export default Copy;
