import React from "react";

const ScheduleIcon = ({ className }) => (
  <svg className={className} fill="none" viewBox="0 0 24 24">
    <style type="text/css">
      {`	.st0{clip-path:url(#SVGID_2_);fill:#0063c6;}
	.st1{clip-path:url(#SVGID_4_);fill:#d8e5ed;} `}
    </style>

    <g>
      <defs>
        <rect id="SVGID_1_" x="-1.48" y="0.14" width="29.58" height="23.72" />
      </defs>
      <clipPath id="SVGID_2_">
        <use xlinkHref="#SVGID_1_" style={{ overflow: "visible;" }} />
      </clipPath>
      <path
        className="st0"
        d="M12,23.86c6.55,0,11.86-5.31,11.86-11.86S18.55,0.14,12,0.14S0.14,5.45,0.14,12S5.45,23.86,12,23.86"
      />
    </g>
    <g>
      <defs>
        <rect id="SVGID_3_" x="4.54" y="6.72" width="14.92" height="10.57" />
      </defs>
      <clipPath id="SVGID_4_">
        <use xlinkHref="#SVGID_3_" style={{ overflow: "visible;" }} />
      </clipPath>
      <path
        className="st1"
        d="M18.15,6.72H5.85c-0.2,0-0.39,0.05-0.56,0.12l6.68,6.21l1.5-1.34c0,0,0,0,0,0l5.24-4.87
		C18.54,6.76,18.35,6.72,18.15,6.72"
      />
      <path
        className="st1"
        d="M19.33,7.41L14.4,12l4.93,4.59c0.08-0.16,0.13-0.33,0.13-0.52V7.94C19.46,7.75,19.41,7.57,19.33,7.41"
      />
      <path
        className="st1"
        d="M4.67,7.41C4.59,7.57,4.54,7.75,4.54,7.94v8.13c0,0.19,0.05,0.36,0.13,0.52L9.6,12L4.67,7.41z"
      />
      <path
        className="st1"
        d="M13.78,12.57l-1.5,1.34c-0.09,0.08-0.2,0.12-0.31,0.12c-0.11,0-0.22-0.04-0.31-0.12l-1.44-1.34l-4.93,4.59
		c0.17,0.08,0.36,0.12,0.56,0.12h12.3c0.2,0,0.39-0.05,0.56-0.12L13.78,12.57z"
      />
    </g>
  </svg>
);

export default ScheduleIcon;
