import React from "react";

const ChevronDown = ({ className }) => (
  <svg className={className} fill="none" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M21.715 7.843l-.929-.929a.563.563 0 00-.797 0l-8.489 8.47-8.489-8.47a.563.563 0 00-.797 0l-.928.929a.563.563 0 000 .796l9.816 9.816c.22.22.576.22.797 0l9.816-9.816a.563.563 0 000-.796z"
    />
  </svg>
);

export default ChevronDown;
