export function validateEmail(email) {
  if (email) {
    //const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //const re = `[a-z0-9._%+-]+[a-z0-9]@[a-z0-9.-]+\.[a-z]{2,4}$`;
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
    if (re.test(email)) {
      const emailText = email.split("@")[0];
      if (emailText && emailText.length > 0) {
        return true;
      }
    }
  }
  return false;
}
