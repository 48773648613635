import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import tw from "tailwind.macro";
import ChevronDownIcon from "components/icons/ChevronDown";
import { useSitecoreContext } from "stores/sitecoreContext";
import tailwindConfig from "tailwind.config.js";
import cx from "classnames";
import resolveConfig from "tailwindcss/resolveConfig";
import { FieldTypes } from "@sitecore-jss/sitecore-jss-react-forms";
import useBreakpoint from "utils/hooks/useBreakpoint";
import breakpoints from "utils/breakpoints";
import {
  validateConditions,
  reviewActions,
} from "utils/sitecoreConditionFunctions";
import { scrollToSection } from "utils/scrollTo";
import { useCountryValue } from "stores/country";
import { validateEmail } from "utils/validateEmail";
import { validateZipCode } from "utils/validateZipCode";
import { validateTel } from "utils/validateTel";

const fullConfig = resolveConfig(tailwindConfig);

const ValidationBubble = styled.div`
  background: #fff;
  margin: 0 auto;
  padding: 6px;
  text-align: center;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 2px;
  border-radius: 5px;
  border: 1px solid #9b9b9b;
  margin-left: 8rem;
  margin-top: 3.5rem;
  z-index: 25;
  color: #0b2c71;
  font-size: 1rem;

  &:after {
    content: "";
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 2px;
    -moz-transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    top: -6px;
    left: 20px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent #fff #fff transparent;
  }
`;

const InputContainer = styled.div.attrs(({ className }) => ({
  className: `w-full flex justify-center mb-2 relative ${className || ""}`,
}))`
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }

  input[type="date"]::-webkit-datetime-edit {
    text-transform: uppercase;
  }
  
  input[type="email"] {
    text-transform: lowercase;
  }

  @media ${breakpoints.md} {
    ${({ inline, width }) =>
      inline
        ? `
          display: inline-block;
          width: ${width};
        `
        : `
          padding: 0 0.175rem;
        `}
  }

  ${({ fieldTypeItemId }) =>
    fieldTypeItemId === FieldTypes.MultipleLineText && "margin-bottom: 1.5rem;"}

  & label {
    display: none;
  }

  & input,
  & textarea {
    outline: none;

    &:focus::-webkit-input-placeholder {
      ${({ error }) =>
        error &&
        ` color: ${fullConfig.theme.colors["field-error"]};
        `};
    }
  }

  & *:not(button):not(svg):not(div):not(option) {
    ${tw`border font-graphie rounded-none font-semibold px-4 py-2 w-full rounded`}
    border-color: rgba(179, 195, 205, 0.9);
    line-height: 1.75rem;
    height: 3.125rem;

    ${({ error }) =>
      error
        ? tw`border-field-error text-field-error`
        : tw`border-form-field-gray`}

    ${({ empty, error }) =>
      empty && !error
        ? tw`bg-form-field-gray font-normal text-white`
        : tw`text-mattamy-gray`}
  }

  & select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    outline: none;

    & > option {
      color: ${fullConfig.theme.colors["white"]};
    }
    ${({ error, empty }) => {
      if (error) return tw`text-field-error`;
      if (!error) return tw`text-white`;
    }}
  }

  & textarea {
    height: 8.125rem !important;
  }
`;

const CheckboxContainer = styled.div`
  & label {
    ${tw`flex items-center`}
    ${({ error }) => {
      if (error) return tw`text-field-error border-field-error`;
    }}
  }
  & input {
    appearance: none;
    width: ${fullConfig.theme.width["4"]};
    min-width: ${fullConfig.theme.width["4"]};
    height: ${fullConfig.theme.height["4"]};
    min-height: ${fullConfig.theme.height["4"]};
    ${({ error }) =>
      error
        ? `border: 2px solid ${fullConfig.theme.colors["field-error"]};`
        : `border: 2px solid ${fullConfig.theme.colors["white"]};`}
    border-radius: 100%;
    margin-right: ${fullConfig.theme.margin["3"]};
    align-self: flex-start;
    margin-top: 0.25rem;

    &:checked {
      background: ${fullConfig.theme.colors["white"]};
    }

    &:focus {
      ${({ isFieldRequired }) =>
        isFieldRequired &&
        `border: 2px solid ${fullConfig.theme.colors["field-error"]};
        color: ${fullConfig.theme.colors["field-error"]};
        `};
    }
  }
`;

const ButtonContainer = styled.div`
  button {
    color: ${fullConfig.theme.colors["mattamy-gray"]};
    font-family: ${fullConfig.theme.fontFamily["trade-gothic-20"][0]};
    letter-spacing: 0.6px;
    border-radius: 10px;
    text-align: center;
    ${({ isMobile }) => (isMobile ? `width: 100%;` : `width: 15.5rem;`)}
    height: 3.75rem;
    background-color: ${fullConfig.theme.colors["form-submit-gray"]};
    padding-top: 2px;
  }
`;

const IconContainer = styled.div.attrs(({ className }) => ({
  className: `absolute w-6 top-0 bottom-0 m-auto flex items-center z-10 ${className}`,
}))`
  pointer-events: none;
  right: 1rem;
`;

const isFieldByName = ({ field }, name) => {
  return field.model && field.model.name === name;
};

const isEmpty = (value) => {
  if (!value) {
    return true;
  }
  if (Array.isArray(value) && (!value.length || !value[0])) {
    return true;
  }
  return false;
};

const getnextInvalidElement = (country) => {
  const formDiv = document.getElementById("ModalForm");
  const requiredInputs = formDiv.querySelectorAll(
    "input[type=text]:required,input[type=email]:required,input[type=tel]:required,textarea:required,select:required"
  );
  let focusIndex = -1;
  //const countrySelect = formDiv.querySelectorAll("select");
  //const country = countrySelect && countrySelect[0].value;

  for (let i = 0; i < requiredInputs.length; i++) {
    if (
      requiredInputs[i].value === "" ||
      (requiredInputs[i].type === "email" &&
        !validateEmail(requiredInputs[i].value)) ||
      (requiredInputs[i].type === "tel" &&
        !validateTel(requiredInputs[i].value)) ||
      (requiredInputs[i].type === "text" &&
        (requiredInputs[i].ariaLabel.includes("Zip") ||
          requiredInputs[i].ariaLabel.includes("zip")) &&
        !validateZipCode(requiredInputs[i].value, country))
    ) {
      focusIndex = focusIndex === -1 ? i : focusIndex;
      requiredInputs[
        i
      ].style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
      requiredInputs[i].style[
        "background-color"
      ] = `${fullConfig.theme.colors["white"]}`;
      if (requiredInputs[i].tagName === "SELECT") {
        requiredInputs[
          i
        ].style.color = `${fullConfig.theme.colors["field-error"]}`;
        requiredInputs[
          i
        ].parentElement.parentElement.lastElementChild.children[0].children[0].style.color = `${fullConfig.theme.colors["field-error"]}`;
        for (let o = 0; o < requiredInputs[i].options.length; o++) {
          requiredInputs[i].options[
            o
          ].style.color = `${fullConfig.theme.colors["mattamy-gray"]}`;
        }
      }
      requiredInputs[i].classList.remove("placeholder_mattamy_white_modal");
      requiredInputs[i].classList.add("placeholder_modal_error");
    }
  }
  if (requiredInputs.length > 0 && focusIndex > -1) {
    return requiredInputs[focusIndex];
  }
  const validateInputs = document.querySelectorAll(
    "input[type=email], input[type=tel]"
  );
  focusIndex = -1;
  for (let i = 0; i < validateInputs.length; i++) {
    if (
      (validateInputs[i].value.length > 0 &&
        validateInputs[i].type === "email" &&
        !validateEmail(validateInputs[i].value)) ||
      (validateInputs[i].value.length > 0 &&
        validateInputs[i].type === "tel" &&
        !validateTel(validateInputs[i].value))
    ) {
      focusIndex = focusIndex === -1 ? i : focusIndex;
      validateInputs[
        i
      ].style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
      if (validateInputs[i].tagName === "SELECT") {
        validateInputs[
          i
        ].style.color = `${fullConfig.theme.colors["field-error"]}`;
        validateInputs[
          i
        ].parentElement.parentElement.lastElementChild.children[0].style.color = `${fullConfig.theme.colors["field-error"]}`;
      }
      validateInputs[i].classList.remove("placeholder_mattamy_white_modal");
      validateInputs[i].classList.add("placeholder_modal_error");
    }
  }
  if (validateInputs.length > 0 && focusIndex > -1) {
    return validateInputs[focusIndex];
  }

  return null;
};

const ScheduleAVisitFieldModal = (props = {}) => {
  const breakpoint = useBreakpoint(
    typeof window !== "undefined" && window.innerWidth
  );
  const isMobile = ["sm", "xs"].includes(breakpoint);

  const { ContextID = {} } = useSitecoreContext() || {};
  const elementRef = useRef(null);
  const [{ country }] = useCountryValue();

  const title = props.field && props.field.model && props.field.model.title;
  const name = props.field && props.field.model && props.field.model.name;
  const fieldTypeItemId = props.field.model.fieldTypeItemId;
  const isFieldRequired = props.field.model.required;
  const fieldConditionKey = props.field.model.conditionSettings.fieldKey;
  const hasConditions =
    props.field.model.conditionSettings &&
    props.field.model.conditionSettings.fieldConditions.length > 0;

  useEffect(() => {
    if (elementRef) {
      const element = elementRef.current;
      if (fieldTypeItemId === FieldTypes.DropdownList) {
        const select = element.querySelector("select");
        let option;
        for (let i = 0; i < select.options.length; i++) {
          option = select.options[i];
          if (option.value !== "") option.setAttribute("lang", "en");
        }
        const emptyOption = select && select.options ? select.options[0] : null;
        if (emptyOption && !emptyOption.value) {
          emptyOption.label = "";
          emptyOption.text = "";
          emptyOption.disabled = true;
          emptyOption.setAttribute("value", "");
        }
      }

      if (fieldTypeItemId === FieldTypes.Button) {
        const submitButton = element.querySelector("button");
        if (submitButton) {
          submitButton.addEventListener("click", function (event) {
            setTimeout(() => {
              const nextFocusInput = getnextInvalidElement(country);
              if (nextFocusInput) {
                scrollToSection(
                  !isMobile ? "sales-center-contact" : "ScheduleAVisit"
                );
                nextFocusInput.focus({ preventScroll: false });
              }
            }, 100);
          });
        }
      }

      const input =
        element &&
        (element.querySelector("input") ||
          element.querySelector("select") ||
          element.querySelector("textarea"));
      if (input) {
        if (name === "ContextID") {
          let labelInput =
            input.parentElement && input.parentElement.firstElementChild;
          if (labelInput) labelInput.innerHTML = name;
        }
        input.setAttribute("aria-label", title || name);
        input.setAttribute("placeholder", "");
        input.classList.add("placeholder_mattamy_white_modal");
        if (isFieldByName(props, "ContextID")) {
          input.setAttribute("value", ContextID.value);
        }
        if (input.type === "email") {
          const re = `[a-z0-9._%+-]+[a-z0-9]@[a-z0-9.-]+\.[a-z]{2,4}$`;
          input.setAttribute("pattern", re);
        }
        if (input.type === "tel") {
          input.setAttribute("aria-label", title + ". Must be 10 digits");
          input.setAttribute("pattern", `[0-9]{3}-[0-9]{3}-[0-9]{4}|[0-9]{10}`);
          input.onkeypress = (e) => {
            let charCode = e && e.which ? e.which : e.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
            const telNumber = e.target.value.replace(/\D/g, "");
            if (telNumber.length > 9) return false;
            return true;
          };
          input.onkeyup = (e) => {
            let target = e.target;
            if (target) {
              let targetValue = e.target.value;
              const keyCode = e.keyCode;
              targetValue = targetValue.replace(/\D/g, "");
              let actualCursorPosition = target.selectionStart;
              if (targetValue.length === 3 && keyCode !== 8) {
                actualCursorPosition += 1;
              }
              if (targetValue.length === 6 && keyCode !== 8) {
                actualCursorPosition += 1;
              }

              let validationBubble = target.parentElement.lastElementChild;
              if (targetValue.length > 0 && targetValue.length < 10) {
                e.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                e.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                validationBubble.textContent =
                  "Phone number must be 10 digits.";
                validationBubble.classList.remove("hidden");
              } else {
                e.target.style.border = `2px solid ${fullConfig.theme.colors["white"]}`;
                e.target.style.color = `${fullConfig.theme.colors["mattamy-gray"]}`;
                validationBubble.classList.add("hidden");
              }

              const telCode = targetValue.substring(0, 3);
              const telPart1 = targetValue.substring(3, 6);
              const telPart2 = targetValue.substring(6, 10);
              if (
                !e.target.value ||
                e.target.value == "-" ||
                e.target.value == "--"
              ) {
                e.target.value = "";
              } else {
                e.target.value = telCode + "-" + telPart1 + "-" + telPart2;
              }
              target.dispatchEvent(new Event("change"));
              target.focus();
              target.setSelectionRange(
                actualCursorPosition,
                actualCursorPosition
              );
            }
          };

          input.addEventListener("invalid", function (event) {
            event.preventDefault();
            let validationBubble = this.parentElement.lastElementChild;
            if (this.value === "") {
              validationBubble.classList.add("hidden");
            } else {
              if (!validateTel(this.value)) {
                validationBubble.classList.remove("hidden");
                validationBubble.textContent =
                  "Phone number must be 10 digits.";
                event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
              } else {
                validationBubble.classList.add("hidden");
                event.target.style.color = `${fullConfig.theme.colors["mattamy-gray"]}`;
              }
            }
          });
        }

        if (input.type === "text") {
          if (
            input.ariaLabel.includes("Zip") ||
            input.ariaLabel.includes("zip")
          ) {
            input.addEventListener("change", (event) => {
              let validationBubble =
                event.target.parentElement.lastElementChild;

              if (event.target.value !== "") {
                //const formDiv = document.getElementById("ModalForm");
                //const countrySelect = formDiv.querySelectorAll("select");

                //if (countrySelect[0]) {
                //const country = countrySelect[0].value;
                if (validateZipCode(event.target.value, country)) {
                  event.target.style.border = `2px solid ${fullConfig.theme.colors["mattamy-blue"]}`;
                  event.target.style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
                  event.target.classList.remove("placeholder_modal_error");
                  event.target.classList.add("placeholder_mattamy_white_modal");
                  validationBubble.classList.add("hidden");
                } else {
                  validationBubble.textContent =
                    "Invalid " +
                    //(country === "USA" ? "US" : "CAN") +
                    " postal Code";
                  event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                  event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                  validationBubble.classList.remove("hidden");
                  event.target.classList.remove(
                    "placeholder_mattamy_white_modal"
                  );
                  event.target.classList.add("placeholder_modal_error");
                }
                //}
              }
            });
          }
        }

        if (isFieldRequired) {
          input.setAttribute("aria-label", "This Field is Required");
          input.setAttribute("required", true);
          input.addEventListener("invalid", function (event) {
            event.preventDefault();
            let validationBubble = this.parentElement.lastElementChild;
            if (this.value === "") {
              validationBubble.classList.remove("hidden");
            } else {
              if (this.type === "email" && !validateEmail(this.value)) {
                validationBubble.classList.remove("hidden");
                validationBubble.textContent = "Invalid email address";
                event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                event.target.classList.add("placeholder_modal_error");
                event.target.classList.remove(
                  "placeholder_mattamy_white_modal"
                );
              } else {
                validationBubble.classList.add("hidden");
                event.target.style.border = `2px solid ${fullConfig.theme.colors["white"]}`;
                event.target.style.color = `${fullConfig.theme.colors["mattamy-gray"]}`;
                event.target.classList.remove("placeholder_modal_error");
                event.target.classList.add("placeholder_mattamy_white_modal");
              }
            }
          });
          if (
            !(
              input.type === "text" &&
              (input.ariaLabel.includes("Zip") ||
                input.ariaLabel.includes("zip"))
            )
          ) {
            input.addEventListener("change", (event) => {
              let validationBubble =
                event.target.parentElement.lastElementChild;
              if (event.target.value !== "") {
                event.target.style.border = `2px solid ${fullConfig.theme.colors["white"]}`;
                event.target.style.color = `${fullConfig.theme.colors["mattamy-gray"]}`;
                if (event.target.tagName === "SELECT") {
                  event.target.style.color = `${fullConfig.theme.colors["mattamy-gray"]}`;
                  event.target.parentElement.parentElement.lastElementChild.children[0].style.color = `${fullConfig.theme.colors["white"]}`;
                  event.target.parentElement.parentElement.lastElementChild.children[0].children[0].style.color = `${fullConfig.theme.colors["mattamy-gray"]}`;
                }
                event.target.classList.remove("placeholder_modal_error");
                event.target.classList.add("placeholder_mattamy_white_modal");
                if (
                  event.target.type === "email" &&
                  !validateEmail(event.target.value)
                ) {
                  validationBubble.textContent = "Invalid email address";
                  validationBubble.classList.remove("hidden");
                  event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                  event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                  event.target.classList.add("placeholder_modal_error");
                  event.target.classList.remove(
                    "placeholder_mattamy_white_modal"
                  );
                } else {
                  validationBubble.classList.add("hidden");
                }
              } else {
                validationBubble.classList.remove("hidden");
              }
            });
          }
        }
      }
    }

    if (isFieldByName(props, "ContextID")) {
      props.onChange(props.field.valueField.name, ContextID.value, true, null);
    }
  }, [elementRef, country]);

  useEffect(() => {
    if (hasConditions) {
      const conditions =
        props.field.model.conditionSettings.fieldConditions[0].conditions;
      const actions =
        props.field.model.conditionSettings.fieldConditions[0].actions;
      const matchType =
        props.field.model.conditionSettings.fieldConditions[0].matchTypeId;

      let flag = validateConditions(conditions, matchType, true);
      reviewActions(actions, flag, true);
    }
  });

  if (isFieldByName(props, "disclaimer")) {
    return <p className="disclaimer">{props.field.model.text}</p>;
  }

  if (isFieldByName(props, "title1") || isFieldByName(props, "title2")) {
    return (
      <h3 className="font-trade-gothic-20 text-4xl text-white mt-4">
        {props.field.model.text}
      </h3>
    );
  }

  if (
    isFieldByName(props, "elqFormName") ||
    isFieldByName(props, "elqPromoCode") ||
    isFieldByName(props, "title") ||
    isFieldByName(props, "subtitle") ||
    isFieldByName(props, "ReCaptcha")
  ) {
    return null;
  }

  if (fieldTypeItemId === FieldTypes.Checkbox) {
    return (
      <CheckboxContainer
        error={props.errors && props.errors[0]}
        id={"Modal-" + fieldConditionKey}
        ref={elementRef}
        className="w-full text-white my-2 flex content-start"
      >
        {props.children}
      </CheckboxContainer>
    );
  }

  if (fieldTypeItemId === FieldTypes.Button) {
    return (
      <ButtonContainer
        isMobile={isMobile}
        className="flex-auto text-right mb-2 mt-10 lg:mt-4"
        ref={elementRef}
      >
        {props.children}
      </ButtonContainer>
    );
  }

  const isAmountField = isFieldByName(props, "amount");
  const isCurrenyField = isFieldByName(props, "currency");

  let width = "100%";
  if (isAmountField) {
    width = "70%";
  } else if (isCurrenyField) {
    width = "30%";
  }

  return (
    <InputContainer
      className={cx(props.field.model.cssClass, {
        "md:pr-10": isAmountField,
      })}
      empty={isEmpty(props.value)}
      title={title}
      error={props.errors && props.errors[0]}
      isFieldRequired={isFieldRequired}
      ref={elementRef}
      fieldTypeItemId={fieldTypeItemId}
      inline={isAmountField || isCurrenyField}
      width={width}
      id={"Modal-" + fieldConditionKey}
    >
      <div className="flex flex-col w-full my-2">
        <div className="text-white font-bold my-2">
          {title + (isFieldRequired && !title.includes("*") ? " *" : "")}
        </div>
        {props.children}
        <ValidationBubble className="hidden">
          {"This field is required"}
        </ValidationBubble>
      </div>
      {props.field.model.items && (
        <IconContainer className="-mr-2 h-full mt-5">
          <ChevronDownIcon
            className={cx("w-4", {
              "text-mattamy-gray": !isEmpty(props.value),
            })}
          />
        </IconContainer>
      )}
    </InputContainer>
  );
};

export default ScheduleAVisitFieldModal;
