import React from "react";

const Calendar = ({ className }) => (
  <svg className={className} fill="none" viewBox="0 0 20 22">
    <title>Calendar Icon</title>
    <g transform="translate(-5, -3.5)" fill="none">
      <path
        fill="currentColor"
        d="M19,3.5 C19.2454599,3.5 19.4496084,3.67687516 19.4919443,3.91012437 L19.5,4 L19.5,6.5 L21,6.5 C22.8685634,6.5 24.3951264,7.9642776 24.4948211,9.8079648 L24.5,10 L24.5,21 C24.5,22.9329966 22.9329966,24.5 21,24.5 L21,24.5 L9,24.5 C7.06700338,24.5 5.5,22.9329966 5.5,21 L5.5,21 L5.5,10 C5.5,8.06700338 7.06700338,6.5 9,6.5 L9,6.5 L10.5,6.5 L10.5,4 C10.5,3.72385763 10.7238576,3.5 11,3.5 C11.2454599,3.5 11.4496084,3.67687516 11.4919443,3.91012437 L11.5,4 L11.5,6.5 L18.5,6.5 L18.5,4 C18.5,3.72385763 18.7238576,3.5 19,3.5 Z M23.5,12.404 L6.5,12.404 L6.5,21 C6.5,22.3254834 7.53153594,23.4100387 8.83562431,23.4946823 L9,23.5 L21,23.5 C22.3807119,23.5 23.5,22.3807119 23.5,21 L23.5,21 L23.5,12.404 Z M20.1765885,13.6559181 C20.3632803,13.8152816 20.4037162,14.0823515 20.2844803,14.2872425 L20.232256,14.3608302 L15.1105472,20.3608302 C14.947021,20.5523984 14.6711797,20.5892683 14.4653938,20.4604097 L14.3918641,20.4042976 L11.5135729,17.7582192 C11.3102826,17.5713297 11.296987,17.2550266 11.4838765,17.0517362 C11.6500004,16.8710337 11.9183772,16.8404505 12.1187428,16.9671436 L12.1903594,17.0220398 L14.686,19.317 L19.4716763,13.7115856 C19.6509603,13.5015573 19.9665602,13.4766341 20.1765885,13.6559181 Z M10.5,7.5 L9,7.5 C7.61928813,7.5 6.5,8.61928813 6.5,10 L6.5,10 L6.5,11.404 L23.5,11.404 L23.5,10 C23.5,8.6745166 22.4684641,7.58996133 21.1643757,7.50531768 L21,7.5 L19.5,7.5 L19.5,9 C19.5,9.27614237 19.2761424,9.5 19,9.5 C18.7545401,9.5 18.5503916,9.32312484 18.5080557,9.08987563 L18.5,9 L18.5,7.5 L11.5,7.5 L11.5,9 C11.5,9.27614237 11.2761424,9.5 11,9.5 C10.7545401,9.5 10.5503916,9.32312484 10.5080557,9.08987563 L10.5,9 L10.5,7.5 Z"
      />
    </g>
  </svg>
);

export default Calendar;
