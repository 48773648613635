import React from "react";

const Heart = ({ className }) => (
  <svg
    className={className}
    fill="none"
    width="23px"
    height="21px"
    viewBox="0 0 23 21"
  >
    <title>Favorite Icon</title>
    <path
      fill="currentColor"
      d="M15.6476192,0.00545766191 C14.1629928,0.0629790907 12.7605407,0.6024792 11.6311957,1.51061283 L11.363613,1.737 L11.3200403,1.69667176 C10.1165207,0.614371834 8.56287225,5.32907052e-15 6.894812,5.32907052e-15 L6.65433502,0.00423844656 C3.22406832,0.125442255 0.518205185,2.83328589 0.0705936848,6.47295301 L0.0456129722,6.695 L0.0568588364,6.62147768 C-0.0468657903,7.24663498 -0.0226482836,8.14303136 0.246910661,9.25886657 C0.730351552,11.2620373 1.84249693,13.0765462 3.45163394,14.5021097 L10.3487642,20.6219939 C10.9131583,21.1227857 11.7617072,21.1263826 12.3303265,20.6303935 L19.3468832,14.5100673 C20.9648991,13.0763098 22.0768074,11.2621459 22.5603584,9.2585424 L22.6277889,8.96018478 C22.8123935,8.08184874 22.8402135,7.3550038 22.7759716,6.80286374 L22.7644683,6.72863201 C22.4063993,2.87510066 19.5367475,0.000332852546 15.9123788,0.000332852546 L15.6476192,0.00545766191 Z M15.9123788,3.00033285 C17.8578794,3.00033285 19.4514188,4.53384328 19.751658,6.7793064 L19.7997188,7.20199453 C19.8034363,7.2637099 19.8052424,7.3484978 19.8023279,7.45574773 C19.7938706,7.76696636 19.7457305,8.13396491 19.644163,8.55440864 L19.5650327,8.85220165 C19.1927464,10.1343116 18.4399377,11.3053984 17.3660434,12.2570392 L11.351613,17.502 L5.44186995,12.257347 C4.28474882,11.232232 3.50048063,9.95266764 3.16310566,8.55473119 C3.0614205,8.13380683 3.01328377,7.76674389 3.00487999,7.45568301 C3.0019885,7.34865606 3.00382797,7.26391478 3.00749365,7.20281035 L3.03020986,7.005621 C3.24999566,4.63756461 4.88466384,3 6.894812,3 C8.19862023,3 9.36559558,3.67675207 10.0613676,4.84084555 C10.6401841,5.80926117 12.0407249,5.81606561 12.6289238,4.85331986 C13.3315267,3.70332113 14.5725199,3.00033285 15.9123788,3.00033285 Z"
    ></path>
  </svg>
);

export default Heart;
