module.exports = {
  theme: {
    extend: {
      borderRadius: {
        default: "0.625rem",
      },
      borderWidth: {
        "1-5": "1.5px",
      },
      colors: {
        "action-blue": "#0063c6",
        "promo-dark-blue": "#002966",
        "black-faded": "#000000e6",
        "black-faded-light": "#00000082",
        aqua: "#50c2cc",
        "mattamy-blue": "#0B2C71",
        "light-blue": "#d8e5ed",
        footer: "#292D32",
        "toolbar-blue": "#0063C6",
        "mattamy-gray": "#292C31",
        "mattamy-gray-light": "#8E8E8E",
        "mattamy-gray-bg": "#E6E6E6",
        "border-light-blue": "#B3C3CD",
        "mattamy-blue-dark": "#02468A",
        "mattamy-blue-light": "#0063C7",
        white: "#FFFFFF",
        "without-image": "#808080",
        "dark-gray-bg": "#3B4450",
        "form-field-gray": "#6E7B8E",
        "form-submit-gray": "#D3D3D3",
        "field-error": "#FF0000",
        "mattamy-gray-font": "#757575",
        "menu-video-galley": "#eff0f5",
        "mattamy-search-gray": "#595959",
        "mattamy-search-ligth-gray": "#EDEDED",
      },
      fontFamily: {
        franklin: ["Libre Franklin", "sans-serif"],
        "trade-gothic-20": ["TradeGothic-BoldCondTwenty"],
        graphie: ["graphie"],
      },
      fontSize: {
        "2xs": "0.625rem",
        "3xs": "0.5rem",
        "4xs": "0.425rem",
        "2xl": "1.375rem",
        "3xl": "1.625rem",
        "5xl": "2.375rem",
        "6xl": "3.75rem",
      },
      lineHeight: {
        tiny: 1.166,
      },
      maxHeight: {
        "10": "10rem",
      },
      inset: {
        "0": 0,
        "1/2": "50%",
      },
      screens: {
        "2xl": "1920px",
        print: {
          raw: "print",
        },
      },
      letterSpacing: {
        wide: "0.025rem",
      },
      spacing: {
        "1/2": "50%",
        "1/3": "33.333333%",
        "2/3": "66.666667%",
        "1/4": "25%",
        "2/4": "50%",
        "3/4": "75%",
        "1/5": "20%",
        "2/5": "40%",
        "3/5": "60%",
        "4/5": "80%",
        "1/6": "16.666667%",
        "2/6": "33.333333%",
        "3/6": "50%",
        "4/6": "66.666667%",
        "5/6": "83.333333%",
        "1/12": "8.333333%",
        "2/12": "16.666667%",
        "3/12": "25%",
        "4/12": "33.333333%",
        "5/12": "41.666667%",
        "6/12": "50%",
        "7/12": "58.333333%",
        "8/12": "66.666667%",
        "9/12": "75%",
        "10/12": "83.333333%",
        "11/12": "91.666667%",
        "1/16": "6.25%",
        "10-mattamy": "0.625rem",
        "14-mattamy": "3.35rem",
        "30-mattamy": "1.875rem",
        "60-mattamy": "3.75rem",
        "90-mattamy": "5.625rem",
      },
    },
  },
  variants: {},
  plugins: [],
};
