import styled from "styled-components";
import breakpoints from "utils/breakpoints";

const ToggleMap = styled.button`
  margin-top: 1.5rem;
  letter-spacing: 0.028em;
  line-height: 1.063rem;
  width: 6.875rem;
  height: 3.125rem;
  pointer-events: all;

  ${({ isMobile }) => !isMobile && "padding-top: 0.438rem"};

  @media ${breakpoints.md} {
    width: 5rem;
    height: 1.625rem;
  }
`;

export default ToggleMap;
