import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import tw from "tailwind.macro";
import ChevronDownIcon from "components/icons/ChevronDown";
import { useSitecoreContext } from "stores/sitecoreContext";
import tailwindConfig from "tailwind.config.js";
import cx from "classnames";
import resolveConfig from "tailwindcss/resolveConfig";
import { FieldTypes } from "@sitecore-jss/sitecore-jss-react-forms";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import useBreakpoint from "utils/hooks/useBreakpoint";
import {
  validateConditions,
  reviewActions,
} from "utils/sitecoreConditionFunctions";
import { validateEmail } from "utils/validateEmail";
import { validateTel } from "utils/validateTel";

const fullConfig = resolveConfig(tailwindConfig);

const ValidationBubble = styled.div`
  background: #fff;
  margin: 0 auto;
  padding: 6px;
  text-align: center;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 2px;
  border-radius: 5px;
  border: 1px solid #9b9b9b;
  margin-left: 8rem;
  margin-top: 3.5rem;
  z-index: 99;
  color: #0b2c71;
  font-size: 1rem;

  &:after {
    content: "";
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 2px;
    -moz-transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    top: -6px;
    left: 20px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent #fff #fff transparent;
  }
`;

const InputContainer = styled.div.attrs(({ className }) => ({
  className: `w-full flex justify-center mb-4 relative ${className || ""}`,
}))`
  input[type="email"]::-webkit-datetime-edit {
    text-transform: lowercase;
  }
  padding: 0 0.175rem;

  ${({ fieldTypeItemId }) =>
    fieldTypeItemId === FieldTypes.MultipleLineText && "margin-bottom: 2.5rem;"}

  & label {
    display: none;
  }

  & input,
  & textarea {
    outline: none;
  }

  & *:not(button):not(svg):not(div):not(option) {
    ${tw`border font-graphie rounded-none font-semibold px-4 py-2 w-full rounded bg-white`}
    border-color: rgba(179, 195, 205, 0.9);
    line-height: 1.75rem;
    height: 3.125rem;

    ${({ empty }) =>
      empty ? tw`bg-form-field-gray font-normal` : tw`text-mattamy-gray`}

    ${({ error }) =>
      error ? tw`border-field-error bg-white` : tw`border-form-field-gray`}
  }

  & select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    outline: none;
    ${({ empty, error }) =>
      `& > option {
          color: ${
            empty && !error
              ? fullConfig.theme.colors["white"]
              : fullConfig.theme.colors["mattamy-gray"]
          }
        }`}
    ${({ empty }) => empty && tw`text-white`}
    ${({ error, empty }) => {
      if (error) return tw`text-field-error`;
      if (!error && !empty) return tw`text-mattamy-gray`;
    }}
  }

  & textarea {
    height: 8.125rem !important;
  }
`;

const CheckboxContainer = styled.div`
  & label {
    ${tw`flex items-center`}
  }
  & input {
    appearance: none;
    width: ${fullConfig.theme.width["5"]};
    min-width: ${fullConfig.theme.width["5"]};
    height: ${fullConfig.theme.height["5"]};
    min-height: ${fullConfig.theme.height["5"]};
    border: 2px solid ${fullConfig.theme.colors["white"]};
    border-radius: 100%;
    margin-right: ${fullConfig.theme.margin["3"]};
    margin-top: 0.15rem;
    align-self: flex-start;

    &:checked {
      background: ${fullConfig.theme.colors["white"]};
    }
  }
`;

const ButtonContainer = styled.div`
  button {
    color: ${fullConfig.theme.colors["mattamy-gray"]};
    font-family: ${fullConfig.theme.fontFamily["trade-gothic-20"][0]};
    letter-spacing: 0.6px;
    border-radius: 10px;
    text-align: center;
    ${({ isMobile }) => (isMobile ? `width: 100%;` : `width: 12.5rem;`)}
    height: 3.125rem;
    background-color: ${fullConfig.theme.colors["form-submit-gray"]};
    padding-top: 2px;
  }
`;

const IconContainer = styled.div.attrs(({ className }) => ({
  className: `absolute w-6 top-0 bottom-0 m-auto flex items-center z-10 ${className}`,
}))`
  pointer-events: none;
  right: 1rem;
`;

const isFieldByName = ({ field }, name) => {
  return field.model && field.model.name === name;
};

const isEmpty = (value) => {
  if (!value) {
    return true;
  }
  if (Array.isArray(value) && (!value.length || !value[0])) {
    return true;
  }
  return false;
};

const getnextInvalidElement = () => {
  const formDiv = document.getElementById("ModalForm");
  const requiredInputs = formDiv.querySelectorAll(
    "input[type=text]:required,input[type=email]:required,textarea:required,select:required"
  );
  let focusIndex = -1;
  for (let i = 0; i < requiredInputs.length; i++) {
    if (
      requiredInputs[i].value === "" ||
      (requiredInputs[i].type === "email" &&
        !validateEmail(requiredInputs[i].value))
    ) {
      focusIndex = focusIndex === -1 ? i : focusIndex;
      requiredInputs[
        i
      ].style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
      requiredInputs[i].style[
        "background-color"
      ] = `${fullConfig.theme.colors["white"]}`;
      if (requiredInputs[i].tagName === "SELECT") {
        requiredInputs[
          i
        ].style.color = `${fullConfig.theme.colors["field-error"]}`;
        requiredInputs[
          i
        ].parentElement.parentElement.lastElementChild.children[0].style.color = `${fullConfig.theme.colors["field-error"]}`;
        for (let o = 0; o < requiredInputs[i].options.length; o++) {
          requiredInputs[i].options[
            o
          ].style.color = `${fullConfig.theme.colors["mattamy-gray"]}`;
        }
      }
      requiredInputs[i].classList.remove("placeholder_mattamy_white_modal");
      requiredInputs[i].classList.add("placeholder_modal_error");
    }
  }
  if (requiredInputs.length > 0 && focusIndex > -1) {
    return requiredInputs[focusIndex];
  }
  return null;
};

const Field = (props = {}) => {
  const breakpoint = useBreakpoint(
    typeof window !== "undefined" && window.innerWidth
  );
  const isMobile = ["sm", "xs"].includes(breakpoint);

  const { ContextID = {} } = useSitecoreContext() || {};
  const elementRef = useRef(null);

  const title = props.field && props.field.model && props.field.model.title;
  const fieldTypeItemId = props.field.model.fieldTypeItemId;
  const isFieldRequired = props.field.model.required;
  const fieldConditionKey = props.field.model.conditionSettings.fieldKey;
  const hasConditions =
    props.field.model.conditionSettings &&
    props.field.model.conditionSettings.fieldConditions.length > 0;

  useEffect(() => {
    const element = elementRef.current;
    if (fieldTypeItemId === FieldTypes.DropdownList) {
      const select = element.querySelector("select");
      const emptyOption = select && select.options ? select.options[0] : null;
      if (emptyOption && !emptyOption.value) {
        emptyOption.label = title;
        emptyOption.text = title;
        emptyOption.disabled = true;
        emptyOption.setAttribute("value", "");
      }
    }

    if (fieldTypeItemId === FieldTypes.Button) {
      const submitButton = element.querySelector("button");
      if (submitButton) {
        submitButton.addEventListener("click", function (event) {
          setTimeout(() => {
            const nextFocusInput = getnextInvalidElement();
            if (nextFocusInput) {
              const modalFormDiv = document.getElementById("ModalForm");
              modalFormDiv.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "start",
              });
              nextFocusInput.focus({ preventScroll: true });
            }
          }, 100);
        });
      }
    }
    const input =
      element &&
      (element.querySelector("input") ||
        element.querySelector("select") ||
        element.querySelector("textarea"));
    if (input) {
      input.classList.add("placeholder_mattamy_white_modal");
      input.setAttribute("placeholder", "");

      if (input.type === "email") {
        const re = `[a-z0-9._%+-]+[a-z0-9]@[a-z0-9.-]+\.[a-z]{2,4}$`;
        input.setAttribute("pattern", re);
      }
      if (input.type === "tel") {
        input.setAttribute("pattern", `[0-9]{3}-[0-9]{3}-[0-9]{4}|[0-9]{10}`);
        input.onkeypress = (e) => {
          let charCode = e && e.which ? e.which : e.keyCode;
          if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
          return true;
        };
        input.onkeyup = (e) => {
          let target = e.target;
          if (target) {
            let targetValue = e.target.value;
            const keyCode = e.keyCode;
            targetValue = targetValue.replace(/\D/g, "");
            let actualCursorPosition = target.selectionStart;
            if (targetValue.length === 3 && keyCode !== 8) {
              actualCursorPosition += 1;
            }
            if (targetValue.length === 6 && keyCode !== 8) {
              actualCursorPosition += 1;
            }

            let validationBubble = target.parentElement.lastElementChild;
            if (targetValue.length > 0 && targetValue.length < 10) {
              e.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
              validationBubble.textContent = "Phone number must be 10 digits.";
              validationBubble.classList.remove("hidden");
            } else {
              e.target.style.border = `2px solid ${fullConfig.theme.colors["mattamy-gray"]}`;
              validationBubble.classList.add("hidden");
            }

            let telCode = targetValue.substring(0, 3);
            let telPart1 = targetValue.substring(3, 6);
            let telPart2 = targetValue.substring(6, 10);
            if (
              !e.target.value ||
              e.target.value == "-" ||
              e.target.value == "--"
            ) {
              e.target.value = "";
            } else {
              e.target.value = telCode + "-" + telPart1 + "-" + telPart2;
            }
            target.focus();
            target.setSelectionRange(
              actualCursorPosition,
              actualCursorPosition
            );
          }
        };
        input.addEventListener("invalid", function (event) {
          event.preventDefault();
          let validationBubble = this.parentElement.lastElementChild;
          if (this.value === "") {
            validationBubble.classList.add("hidden");
          } else {
            if (!validateTel(this.value)) {
              validationBubble.classList.remove("hidden");
              validationBubble.textContent = "Phone number must be 10 digits.";
            } else {
              validationBubble.classList.add("hidden");
            }
          }
        });
      }
      if (isFieldRequired) {
        input.setAttribute("aria-label", "This Field is Required");
        input.setAttribute("required", true);
        input.addEventListener("invalid", function (event) {
          event.preventDefault();
          let validationBubble = this.parentElement.lastElementChild;
          if (this.value === "") {
            validationBubble.classList.remove("hidden");
          } else {
            if (this.type === "email" && !validateEmail(this.value)) {
              validationBubble.classList.remove("hidden");
              validationBubble.textContent = "Invalid email address";
            } else {
              validationBubble.classList.add("hidden");
            }
          }
        });

        input.addEventListener("change", (event) => {
          let validationBubble = event.target.parentElement.lastElementChild;
          if (event.target.value !== "") {
            event.target.style.border = `2px solid ${fullConfig.theme.colors["form-field-gray"]}`;
            if (event.target.tagName === "SELECT") {
              event.target.style.color = `${fullConfig.theme.colors["mattamy-gray"]}`;
              event.target.parentElement.parentElement.lastElementChild.children[0].style.color = `${fullConfig.theme.colors["mattamy-gray"]}`;
            }
            event.target.classList.remove("placeholder_modal_error");
            event.target.classList.add("placeholder_mattamy_white_modal");
            if (
              event.target.type === "email" &&
              !validateEmail(event.target.value)
            ) {
              validationBubble.textContent = "Invalid email address";
              validationBubble.classList.remove("hidden");
            } else {
              validationBubble.classList.add("hidden");
            }
          } else {
            validationBubble.classList.remove("hidden");
          }
        });
      }
    }

    if (isFieldByName(props, "ContextID")) {
      props.onChange(props.field.valueField.name, ContextID.value, true, null);
    }
  }, [elementRef]);

  useEffect(() => {
    if (hasConditions) {
      const conditions =
        props.field.model.conditionSettings.fieldConditions[0].conditions;
      const actions =
        props.field.model.conditionSettings.fieldConditions[0].actions;
      const matchType =
        props.field.model.conditionSettings.fieldConditions[0].matchTypeId;

      let flag = validateConditions(conditions, matchType);
      reviewActions(actions, flag);
    }
  });

  if (isFieldByName(props, "disclaimer")) {
    return <p className="disclaimer">{props.field.model.text}</p>;
  }

  if (
    isFieldByName(props, "elqFormName") ||
    isFieldByName(props, "title") ||
    isFieldByName(props, "subtitle") ||
    isFieldByName(props, "ReCaptcha")
  ) {
    return null;
  }

  if (fieldTypeItemId === FieldTypes.Checkbox) {
    return (
      <CheckboxContainer
        error={props.errors && props.errors[0]}
        id={fieldConditionKey}
        ref={elementRef}
        className="w-full text-white mb-2 flex content-start justify-right"
      >
        {props.children}
      </CheckboxContainer>
    );
  }

  if (fieldTypeItemId === FieldTypes.Button) {
    return (
      <ButtonContainer
        isMobile={isMobile}
        className="text-right mb-2 mt-8 lg:mt-4"
        ref={elementRef}
      >
        {props.children}
      </ButtonContainer>
    );
  }

  if (fieldTypeItemId === "{F6ABF2D4-D864-48D7-A464-75025A42162E}") {
    return (
      <RichText
        tag="span"
        className="richtext text-white "
        field={{ value: props.field.model.html }}
      />
    );
  }

  return (
    <InputContainer
      className={props.field.model.cssClass}
      empty={isEmpty(props.value)}
      title={title}
      error={props.errors && props.errors[0]}
      ref={elementRef}
      isDate={isFieldByName(props, "date")}
      fieldTypeItemId={fieldTypeItemId}
      id={fieldConditionKey}
      isFieldRequired={isFieldRequired}
    >
      <div className="flex flex-col w-full">
        <div className="hidden md:block text-white text-sm my-2">
          {fieldTypeItemId !== "{E0CFADEE-1AC0-471D-A820-2E70D1547B4B}" &&
            title}
        </div>

        {props.children}
        <ValidationBubble className="hidden">
          {"This field is required"}
        </ValidationBubble>
      </div>

      {props.field.model.items && (
        <IconContainer className="-mr-2 md:pt-3 mt-1">
          <ChevronDownIcon
            className={cx("w-4", {
              "text-mattamy-gray": !isEmpty(props.value),
              "text-white": isEmpty(props.value),
            })}
          />
        </IconContainer>
      )}
    </InputContainer>
  );
};

export default Field;
