import React, { useReducer, useEffect } from "react";
import { SitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Route, Switch } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import componentFactory from "./temp/componentFactory";
import RouteHandler from "./RouteHandler";
import { ModalProvider } from "styled-react-modal";
import { CountryContext, countryReducer } from "./stores/country";
import AOS from "aos";
import { ModalBackground } from "components/components/atoms";
import { initialState, UiContext, uiReducer } from "stores/uiContext";
// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  "/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*",
  "/:lang([a-z]{2})/:sitecoreRoute*",
  "/:sitecoreRoute*",
];

const Wrapper = ({ children, savedCountry }) => (
  <ModalProvider backgroundComponent={ModalBackground}>
    <CountryContext.Provider
      value={useReducer(countryReducer, { country: savedCountry })}
    >
      <UiContext.Provider value={useReducer(uiReducer, initialState)}>
        {children}
      </UiContext.Provider>
    </CountryContext.Provider>
  </ModalProvider>
);

// wrap the app with:
// ApolloProvider: provides an instance of Apollo GraphQL client to the app to make Connected GraphQL queries.
//    Not needed if not using connected GraphQL.
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
class AppRoot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ssrRenderComplete: false,
      //contextFactory: new SitecoreContextFactory(),
    };

    if (
      props.ssrState &&
      props.ssrState.sitecore &&
      props.ssrState.sitecore.route
    ) {
      // set the initial sitecore context data if we got SSR initial state
      this.sitecoreContext = {
        route: props.ssrState.sitecore.route,
        itemId: props.ssrState.sitecore.route.itemId,
        ...props.ssrState.sitecore.context,
      };
    } else if (props.ssrState) {
      this.sitecoreContext = props.ssrState.sitecore.context;
    } else {
      this.sitecoreContext = null;
    }
  }

  setSsrRenderComplete = (ssrRenderComplete) => {
    this.setState({
      ssrRenderComplete,
    });
  };

  componentDidMount = () => {
    this.setSsrRenderComplete(true);
    AOS.init({ duration: 800, once: false });
  };

  render() {
    const { path, Router, graphQLClient } = this.props;

    let savedCountry;

    if (typeof window !== "undefined" && localStorage) {
      savedCountry = localStorage.getItem("userCountry");
    }

    const routeRenderFunction = (props) => (
      <Wrapper savedCountry={savedCountry}>
        <RouteHandler
          route={props}
          ssrRenderComplete={this.state.ssrRenderComplete}
          setSsrRenderComplete={this.setSsrRenderComplete}
          context={this.sitecoreContext}
        />
      </Wrapper>
    );

    return (
      <ApolloProvider client={graphQLClient}>
        <SitecoreContext
          componentFactory={componentFactory}
          context={this.sitecoreContext}
        >
          <Router location={path} context={{}}>
            <Switch>
              {routePatterns.map((routePattern) => (
                <Route
                  key={routePattern}
                  path={routePattern}
                  render={routeRenderFunction}
                />
              ))}
            </Switch>
          </Router>
        </SitecoreContext>
      </ApolloProvider>
    );
  }
}

export default AppRoot;
