import React, { useState, useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styled from "styled-components";
import { useCountryValue, switchCountry, selectCountry } from "stores/country";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import useOnClickOutside from "utils/hooks/useOnClickOutside";
import Check from "components/icons/Check";
import useBreakpoint from "utils/hooks/useBreakpoint";
import breakpoints from "utils/breakpoints";
import ChevronDown from "components/icons/ChevronDown";
import { Transition } from "react-spring/renderprops";

const DropDown = styled.div`
  height: 2rem;
  width: 6rem;
`;

const DropDownContent = styled.div`
  top: 100%;
  width: 6rem;

  @media ${breakpoints.sm} {
    right: unset;
  }

  @media ${breakpoints.md} {
    left: 0;
  }
`;

const LabelContainer = styled.div`
  width: 87%;
  line-height: 1.125rem;
`;

const Dropdown = (props) => {
  const {
    label,
    children,
    active,
    setActive = () => {},
    color = "blue",
    dropdownButtonRef,
  } = props;

  const breakpoint = useBreakpoint(
    typeof window !== "undefined" && window.innerWidth
  );
  const isMobile = ["sm", "xs"].includes(breakpoint);

  // Click outside functionality
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setActive(false));

  return (
    <DropDown
      className="relative h-full text-xs mt-1 font-trade-gothic-20 select-none"
      ref={dropdownRef}
      tabIndex="-1"
    >
      <button
        className={cx(
          " h-full w-full flex justify-between cursor-pointer text-left",
          "rounded-lg transition transition-all duration-300 px-4 ",
          {
            "bg-white text-mattamy-blue": color === "white",
            "bg-mattamy-blue text-white": color === "blue",
          }
        )}
        ref={dropdownButtonRef}
        active={active}
        isMobile={isMobile}
        onClick={(e) => {
          setActive(!active);
        }}
        aria-label={"Select your country. " + label + " country is selected"}
        aria-expanded={active}
      >
        <LabelContainer
          className={cx(
            "h-full flex flex-col justify-center text-sm text-center overflow-visible",
            {}
          )}
        >
          <span className={cx("whitespace-pre mt-1 truncate", {})}>
            {label}
          </span>
        </LabelContainer>
        <div className={cx("flex items-center justify-center h-full", {})}>
          <ChevronDown className="w-10-mattamy ml-3" />
        </div>
      </button>
      <Transition
        items={active}
        config={{ duration: 100 }}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}
      >
        {(active) =>
          active &&
          ((props) => (
            <div style={props}>
              <DropDownContent
                className="absolute bg-white text-mattamy-blue text-xs
                    mt-3 z-50 rounded-lg font-trade-gothic-20 tracking-tighter" //p-3
              >
                {children}
              </DropDownContent>
            </div>
          ))
        }
      </Transition>
    </DropDown>
  );
};

const CountriesList = styled.div`
  display: inline-grid;
  column-gap: 5rem;
`;
const Button = styled.button`
  width: 6rem;
  &:nth-last-child(1) {
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  &:nth-child(1) {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0rem;
    border-bottom-left-radius: 0rem;
  }
`;

const CountrySelect = ({
  color = "blue",
  sitecoreContext,
  updateSitecoreContext,
  onKeyDown = null,
  id = "countryContainer",
}) => {
  const [activeState, setActiveState] = useState(false);

  const breakpoint = useBreakpoint(
    typeof window !== "undefined" && window.innerWidth
  );
  const isMobile = ["sm", "xs"].includes(breakpoint);

  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search);
  const savedCountry = params.country;
  const [{ country }, dispatch] = useCountryValue();

  const toggleCountry = useCallback(() => {
    dispatch(switchCountry());
    //console.log("toggleCountry");
    var uc = country === "USA" ? "CAN" : "USA";
    if (typeof window !== "undefined" && localStorage) {
      localStorage.setItem("userCountry", uc);
    } else {
      console.log("toogleCountry", "unable to set local storage");
    }
    if (location.pathname === "/") {
      //console.log("location.pathname",uc);
      const newParams = { country: uc };
      history.push({
        pathname: location.pathname,
        search: "?" + new URLSearchParams(newParams).toString(),
        hash: location.hash,
      });
    }
  }, [country]);

  useEffect(() => {
    //console.log("country change");
    const language = country === "CAN" ? "en-CA" : "en";
    //localStorage.setItem("userCountry", country);

    if (sitecoreContext.language !== language) {
      updateSitecoreContext({
        ...sitecoreContext,
        language: language,
        refresh: true,
      });
    }
  }, [country]);

  useEffect(() => {
    if (location.pathname === "/" && country) {
      const newParams = { country };
      history.push({
        pathname: location.pathname,
        search: "?" + new URLSearchParams(newParams).toString(),
        hash: location.hash,
      });
    }
  }, []);

  const dropdownButtonRef = useRef();
  return (
    <div id={id}>
      <Dropdown
        label={country === "USA" ? "USA" : "CANADA"}
        active={activeState}
        setActive={setActiveState}
        color={color}
        dropdownButtonRef={dropdownButtonRef}
      >
        <div className="overflow-y-auto lg:flex w-full md:w-auto">
          <CountriesList>
            <Button
              className={cx(
                "relative flex items-center tracking-normal text-sm whitespace-no-wrap p-1",
                {
                  " bg-mattamy-blue text-white": country === "USA",
                }
              )}
              key={"USA"}
              onClick={(e) => {
                e.preventDefault();
                if (country !== "USA") toggleCountry();
                setActiveState(false);
                if (dropdownButtonRef.current) {
                  dropdownButtonRef.current.focus();
                }
              }}
              aria-label="Country selector. USA"
              aria-current={country === "USA"}
            >
              <div className="w-5 mr-3 ml-2">
                {country === "USA" ? <Check /> : ""}
              </div>
              <span className="mt-1">USA</span>
            </Button>
            <Button
              className={cx(
                "relative flex items-center tracking-normal text-sm whitespace-no-wrap p-1",
                {
                  " bg-mattamy-blue text-white": country !== "USA",
                }
              )}
              key={"CANADA"}
              onClick={(e) => {
                e.preventDefault();
                if (country === "USA") toggleCountry();
                setActiveState(false);
                if (dropdownButtonRef.current) {
                  dropdownButtonRef.current.focus();
                }
              }}
              aria-label="Country selector. CANADA"
              aria-current={country !== "USA"}
            >
              <div className="w-5 mr-3 ml-2">
                {country !== "USA" ? <Check /> : ""}
              </div>
              <span className="mt-1">CANADA</span>
            </Button>
          </CountriesList>
        </div>
      </Dropdown>
    </div>
  );
};

CountrySelect.propTypes = {
  color: PropTypes.oneOf(["white", "blue"]),
  className: PropTypes.string,
};

export default withSitecoreContext({ updatable: true })(CountrySelect);
