function cubicInOut(t) {
  return t < 0.5 ? 4.0 * t * t * t : 0.5 * Math.pow(2.0 * t - 2.0, 3.0) + 1.0;
}

export function getElementY(element) {
  const headerHeight = document.getElementById("header").clientHeight;
  return (
    window.pageYOffset + element.getBoundingClientRect().top - headerHeight
  );
}

export function doScrolling(element, duration, easing, height) {
  const startingY = window.pageYOffset;
  const elementY = getElementY(element) - height;

  const targetY =
    document.body.scrollHeight - elementY < window.innderHeight
      ? document.body.scrollHeight - window.innerHeight
      : elementY;

  const diff = targetY - startingY;

  let start;
  if (!diff) return;

  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    const time = timestamp - start;
    const percent = easing(Math.min(time / duration, 1));

    window.scrollTo(0, startingY + diff * percent);

    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  });
}

export function doScrollingTop(duration, easing) {
  const startingY = window.pageYOffset;
  const elementY = 0;

  const targetY =
    document.body.scrollHeight - elementY < window.innderHeight
      ? document.body.scrollHeight - window.innerHeight
      : elementY;

  const diff = targetY - startingY;

  let start;
  if (!diff) return;

  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    const time = timestamp - start;
    const percent = easing(Math.min(time / duration, 1));

    window.scrollTo(0, startingY + diff * percent);

    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  });
}

export function scrollToSection(id, headerHeight = 0) {
  const target = document.getElementById(id);
  if (!target || typeof window === "undefined") return;
  doScrolling(target, 250, cubicInOut, headerHeight);
  target.focus();
}

export function scrollToTop() {
  if (typeof window === "undefined") return;
  doScrollingTop(250, cubicInOut);
}