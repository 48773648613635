import React from "react";

const OpenNewTab = ({ className }) => (
  <svg className={className} fill="none" viewBox="0, 0, 400,400">
    <title>OpenNewTab Icon</title>
    <g>
      <path
        d="M96.403 80.795 C 88.882 83.150,82.355 90.067,80.495 97.656 C 79.884 100.151,79.698 130.440,79.856 201.953 L 80.078 302.734 81.884 306.401 C 84.270 311.245,88.694 315.703,93.425 318.032 L 97.266 319.922 200.000 319.922 L 302.734 319.922 306.402 318.115 C 319.348 311.739,319.878 309.768,320.147 266.992 L 320.372 231.250 312.139 231.250 L 303.906 231.250 303.906 263.697 C 303.906 300.088,303.828 300.696,298.881 302.762 C 296.528 303.746,282.643 303.906,200.000 303.906 C 93.413 303.906,99.461 304.202,97.238 298.881 C 95.516 294.761,95.516 105.239,97.238 101.119 C 99.327 96.117,99.527 96.094,140.209 96.094 L 176.563 96.094 176.563 87.891 L 176.563 79.688 138.086 79.740 C 109.064 79.779,98.822 80.039,96.403 80.795 M255.469 87.891 L 255.469 96.094 273.631 96.094 L 291.794 96.094 218.752 169.138 L 145.711 242.183 151.764 248.236 L 157.817 254.289 230.862 181.248 L 303.906 108.206 303.906 126.369 L 303.906 144.531 312.109 144.531 L 320.313 144.531 320.313 115.271 C 320.313 75.493,324.498 79.688,284.804 79.688 L 255.469 79.688 255.469 87.891 "
        stroke="none"
        fill="currentColor"
        fillRule="evenodd"
      ></path>
    </g>
  </svg>
);

export default OpenNewTab;
