import React from "react";

const MapMarker = ({ className }) => (
  <svg
    className={className}
    width="16px"
    height="23px"
    viewBox="0 0 16 23"
    fill="none"
  >
    <title>Map Location Icon</title>
    <path
      fill="currentColor"
      d="M7.94545455,0 C12.3336079,0 15.8909091,3.55730117 15.8909091,7.94545455 C15.8909091,10.8708901 13.2424242,15.8890719 7.94545455,23 C2.64848485,15.8890719 4.97379915e-14,10.8708901 4.97379915e-14,7.94545455 C4.97379915e-14,3.55730117 3.55730117,0 7.94545455,0 Z M7.94545455,5.10909091 C6.2886003,5.10909091 4.94545455,6.45223666 4.94545455,8.10909091 C4.94545455,9.76594516 6.2886003,11.1090909 7.94545455,11.1090909 C9.60230879,11.1090909 10.9454545,9.76594516 10.9454545,8.10909091 C10.9454545,6.45223666 9.60230879,5.10909091 7.94545455,5.10909091 Z"
    />
  </svg>
);

export default MapMarker;
