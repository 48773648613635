import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import cx from "classnames";
import breakpoints from "utils/breakpoints";

const Container = styled.div`
  @media ${breakpoints.md} {
    height: 100%;
  }
`;

const Image = ({ src, alt, className }) => (
  <Container className="w-full h-full">
    <img
      src={src}
      alt={alt}
      className={cx("object-cover rounded w-full h-full", className)}
    ></img>
  </Container>
);

Image.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.string,
};

export default Image;
