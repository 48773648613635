import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import tw from "tailwind.macro";
import ChevronDownIcon from "components/icons/ChevronDown";
import { useSitecoreContext } from "stores/sitecoreContext";
import tailwindConfig from "tailwind.config.js";
import cx from "classnames";
import resolveConfig from "tailwindcss/resolveConfig";
import { FieldTypes } from "@sitecore-jss/sitecore-jss-react-forms";
import useBreakpoint from "utils/hooks/useBreakpoint";
import breakpoints from "utils/breakpoints";
import {
  validateConditions,
  reviewActions,
} from "utils/sitecoreConditionFunctions";
import { scrollToSection } from "utils/scrollTo";
import { useCountryValue } from "stores/country";
import { validateEmail } from "utils/validateEmail";
import { validateZipCode } from "utils/validateZipCode";
import { validateTel } from "utils/validateTel";

const fullConfig = resolveConfig(tailwindConfig);

const ValidationBubble = styled.div`
  background: #fff;
  padding: 6px;
  text-align: center;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 2px;
  border-radius: 5px;
  border: 1px solid #9b9b9b;
  z-index: 99;
  color: #0b2c71;
  font-size: 1rem;
  ${({ isMultipleLineText }) => isMultipleLineText && "margin-top: 11rem;"}

  &:after {
    content: "";
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 2px;
    -moz-transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    top: -6px;
    left: 20px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent #fff #fff transparent;
  }
`;

const InputContainer = styled.div.attrs(({ className }) => ({
  className: `w-full flex justify-center mb-4 relative ${className || ""}`,
}))`

  input[type=date]::-webkit-calendar-picker-indicator {
    display: none;
  }

  input[type=date]::-webkit-datetime-edit {
    text-transform: uppercase;
  }
  
  input[type="email"] {
    text-transform: lowercase;
  }

  @media ${breakpoints.md} {
    ${({ inline, width }) =>
      inline
        ? `
          display: inline-block;
          width: ${width};
        `
        : `
          padding: 0 0.175rem;
        `}
  }

  ${({ fieldTypeItemId }) =>
    fieldTypeItemId === FieldTypes.MultipleLineText && "margin-bottom: 2.5rem;"}

  & label {
    display: none;
  }

  & input,
  & textarea {
    outline: none;
  }

  & *:not(button):not(svg):not(div):not(option) {
    ${tw`border font-graphie rounded-none font-semibold px-4 py-2 w-full rounded bg-white text-mattamy-blue bg-white font-normal`}
    border-color: rgba(179, 195, 205, 0.9);
    line-height: 1.75rem;
    height: 3.125rem;

    ${({ error }) =>
      error ? tw`border-field-error bg-white` : tw`border-white`}
  }

  & select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";

    ${({ empty, error }) =>
      `& > option {
          color: ${
            empty && !error
              ? fullConfig.theme.colors["mattamy-blue"]
              : fullConfig.theme.colors["mattamy-blue"]
          }
        }`}
    ${({ empty }) => empty && tw`text-mattamy-blue`}
    ${({ error, empty }) => {
      if (error) return tw`text-field-error`;
      if (!error && !empty) return tw`text-mattamy-blue`;
    }}
  }

  & textarea {
    height: 8.125rem !important;
  }
`;

const CheckboxContainer = styled.div`
  & label {
    ${tw`flex items-center`}
    ${({ error }) => {
      if (error) return tw`text-field-error border-field-error`;
    }}
  }
  & input {
    appearance: none;
    width: ${fullConfig.theme.width["4"]};
    min-width: ${fullConfig.theme.width["4"]};
    height: ${fullConfig.theme.height["4"]};
    min-height: ${fullConfig.theme.height["4"]};
    ${({ error }) =>
      error
        ? `border: 2px solid ${fullConfig.theme.colors["field-error"]};`
        : `border: 2px solid ${fullConfig.theme.colors["mattamy-blue"]};`}
    border-radius: 100%;
    margin-right: ${fullConfig.theme.margin["3"]};
    align-self: flex-start;
    margin-top: 0.25rem;

    &:checked {
      background: ${fullConfig.theme.colors["mattamy-blue"]};
    }
  }
`;

const ButtonContainer = styled.div`
  button {
    color: ${fullConfig.theme.colors["white"]};
    font-family: ${fullConfig.theme.fontFamily["trade-gothic-20"][0]};
    letter-spacing: 0.6px;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    height: 3.75rem;
    background-color: ${fullConfig.theme.colors["action-blue"]};
    padding-top: 2px;
  }
`;

const IconContainer = styled.div.attrs(({ className }) => ({
  className: `absolute w-6 top-0 bottom-0 m-auto flex items-center z-10 ${className}`,
}))`
  pointer-events: none;
  right: 1rem;
`;

const isFieldByName = ({ field }, name) => {
  return field.model && field.model.name === name;
};

const isEmpty = (value) => {
  if (!value) {
    return true;
  }
  if (Array.isArray(value) && (!value.length || !value[0])) {
    return true;
  }
  return false;
};

const getnextInvalidElement = (country) => {
  const requiredInputs = document.querySelectorAll(
    "input[type=text]:required,input[type=email]:required,input[type=date]:required,input[type=tel]:required,input[type=checkbox]:required,textarea:required,select:required"
  );
  let focusIndex = -1;
  for (let i = 0; i < requiredInputs.length; i++) {
    if (
      requiredInputs[i].value === "" ||
      (requiredInputs[i].type === "email" &&
        !validateEmail(requiredInputs[i].value)) ||
      (requiredInputs[i].type === "checkbox" && !requiredInputs[i].checked) ||
      (requiredInputs[i].type === "tel" &&
        !validateTel(requiredInputs[i].value)) ||
      (requiredInputs[i].type === "text" &&
        (requiredInputs[i].ariaLabel.includes("Zip") ||
          requiredInputs[i].ariaLabel.includes("zip")) &&
        !validateZipCode(requiredInputs[i].value, country))
    ) {
      focusIndex = focusIndex === -1 ? i : focusIndex;
      requiredInputs[
        i
      ].style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
      if (requiredInputs[i].tagName === "SELECT") {
        requiredInputs[
          i
        ].style.color = `${fullConfig.theme.colors["field-error"]}`;
        requiredInputs[
          i
        ].parentElement.parentElement.lastElementChild.children[0].style.color = `${fullConfig.theme.colors["field-error"]}`;
      }
      requiredInputs[i].classList.remove("placeholder_mattamy_blue");
      requiredInputs[i].classList.add("placeholder_error");
      if (requiredInputs[i].type === "date") {
        requiredInputs[i].style.color = fullConfig.theme.colors["field-error"];
      }
    }
  }

  if (requiredInputs.length > 0 && focusIndex > -1) {
    return requiredInputs[focusIndex];
  }
  const validateInputs = document.querySelectorAll(
    "input[type=email], input[type=tel]"
  );
  focusIndex = -1;
  for (let i = 0; i < validateInputs.length; i++) {
    if (
      (validateInputs[i].value.length > 0 &&
        validateInputs[i].type === "email" &&
        !validateEmail(validateInputs[i].value)) ||
      (validateInputs[i].value.length > 0 &&
        validateInputs[i].type === "tel" &&
        !validateTel(validateInputs[i].value))
    ) {
      focusIndex = focusIndex === -1 ? i : focusIndex;
      validateInputs[
        i
      ].style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
      if (validateInputs[i].tagName === "SELECT") {
        validateInputs[
          i
        ].style.color = `${fullConfig.theme.colors["field-error"]}`;
        validateInputs[
          i
        ].parentElement.parentElement.lastElementChild.children[0].style.color = `${fullConfig.theme.colors["field-error"]}`;
      }
      validateInputs[i].classList.remove("placeholder_mattamy_blue");
      validateInputs[i].classList.add("placeholder_error");
    }
  }
  if (validateInputs.length > 0 && focusIndex > -1) {
    return validateInputs[focusIndex];
  }

  return null;
};

const InvestorRelationsField = (props = {}) => {
  const breakpoint = useBreakpoint(
    typeof window !== "undefined" && window.innerWidth
  );
  const isMobile = ["sm", "xs"].includes(breakpoint);

  const { ContextID = {} } = useSitecoreContext() || {};
  const elementRef = useRef(null);
  const [{ country }] = useCountryValue();

  const title = props.field && props.field.model && props.field.model.title;
  const name = props.field && props.field.model && props.field.model.name;
  const fieldTypeItemId = props.field.model.fieldTypeItemId;
  const isFieldRequired = props.field.model.required;
  const fieldConditionKey = props.field.model.conditionSettings.fieldKey;
  const hasConditions =
    props.field.model.conditionSettings &&
    props.field.model.conditionSettings.fieldConditions.length > 0;

  useEffect(() => {
    if (elementRef) {
      const element = elementRef.current;
      if (fieldTypeItemId === FieldTypes.DropdownList) {
        const select = element.querySelector("select");
        const emptyOption = select && select.options ? select.options[0] : null;
        if (emptyOption && !emptyOption.value) {
          emptyOption.label = "";
          emptyOption.text = "";
          emptyOption.disabled = true;
          emptyOption.setAttribute("value", "");
        }
      }

      if (fieldTypeItemId === FieldTypes.Button) {
        const submitButton = element.querySelector("button");
        if (submitButton) {
          submitButton.addEventListener("click", function (event) {
            setTimeout(() => {
              const nextFocusInput = getnextInvalidElement(country);
              if (!isMobile) scrollToSection("title2");
              if (nextFocusInput) {
                nextFocusInput.focus({ preventScroll: false });
              }
            }, 100);
          });
        }
      }

      const input =
        element &&
        (element.querySelector("input") ||
          element.querySelector("select") ||
          element.querySelector("textarea"));
      if (input) {
        if (name === "ContextID") {
          let labelInput =
            input.parentElement && input.parentElement.firstElementChild;
          if (labelInput) labelInput.innerHTML = name;
        }
        input.setAttribute("aria-label", title || name);
        input.setAttribute("placeholder", "");
        input.classList.add("placeholder_mattamy_blue");
        if (input.type === "email") {
          const re = `[a-z0-9._%+-]+[a-z0-9]@[a-z0-9.-]+\.[a-z]{2,4}$`;
          input.setAttribute("pattern", re);

          input.addEventListener("change", (event) => {
            let validationBubble = event.target.parentElement.lastElementChild;
            if (event.target.value !== "") {
              event.target.style.border = `2px solid ${fullConfig.theme.colors["mattamy-blue"]}`;
              event.target.style.color =
                fullConfig.theme.colors["mattamy-blue"];
              event.target.classList.remove("placeholder_error");
              event.target.classList.add("placeholder_mattamy_blue");
              if (!validateEmail(event.target.value)) {
                validationBubble.textContent =
                  "Error, Email addresses must contain a username, ‘@’, and ‘.com’";

                event.target.setAttribute(
                  "aria-label",
                  "Error, Email addresses must contain a username, ‘@’, and ‘.com’"
                );
                validationBubble.classList.remove("hidden");
              } else {
                validationBubble.classList.add("hidden");
                event.target.setAttribute(
                  "aria-label",
                  "Email addresses must contain a username, ‘@’, and ‘.com’"
                );
              }
            }
          });
        }
        if (input.type === "tel") {
          input.setAttribute("aria-label", title + ". Must be 10 digits");
          input.setAttribute("pattern", `[0-9]{3}-[0-9]{3}-[0-9]{4}|[0-9]{10}`);
          input.onkeypress = (e) => {
            let charCode = e && e.which ? e.which : e.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
            const telNumber = e.target.value.replace(/\D/g, "");
            if (telNumber.length > 9) return false;
            return true;
          };
          input.onkeyup = (e) => {
            let target = e.target;
            if (target) {
              let targetValue = e.target.value;
              const keyCode = e.keyCode;
              targetValue = targetValue.replace(/\D/g, "");
              let actualCursorPosition = target.selectionStart;
              if (targetValue.length === 3 && keyCode !== 8) {
                actualCursorPosition += 1;
              }
              if (targetValue.length === 6 && keyCode !== 8) {
                actualCursorPosition += 1;
              }

              let validationBubble = target.parentElement.lastElementChild;
              if (targetValue.length > 0 && targetValue.length < 10) {
                e.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                e.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                validationBubble.textContent =
                  "Phone number must be 10 digits.";
                validationBubble.classList.remove("hidden");
              } else {
                e.target.style.border = `2px solid ${fullConfig.theme.colors["mattamy-blue"]}`;
                e.target.style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
                validationBubble.classList.add("hidden");
              }

              const telCode = targetValue.substring(0, 3);
              const telPart1 = targetValue.substring(3, 6);
              const telPart2 = targetValue.substring(6, 10);
              if (
                !e.target.value ||
                e.target.value == "-" ||
                e.target.value == "--"
              ) {
                e.target.value = "";
              } else {
                e.target.value = telCode + "-" + telPart1 + "-" + telPart2;
              }
              target.dispatchEvent(new Event("change"));
              target.focus();
              target.setSelectionRange(
                actualCursorPosition,
                actualCursorPosition
              );
            }
          };
          input.addEventListener("invalid", function (event) {
            event.preventDefault();
            let validationBubble = this.parentElement.lastElementChild;
            if (this.value === "") {
              validationBubble.classList.add("hidden");
            } else {
              if (!validateTel(this.value)) {
                validationBubble.classList.remove("hidden");
                validationBubble.textContent =
                  "Phone number must be 10 digits.";
                event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
              } else {
                validationBubble.classList.add("hidden");
                event.target.style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
              }
            }
          });
        }
        if (input.type === "text") {
          input.setAttribute("autocomplete", "on");

          if (
            input.ariaLabel.includes("Zip") ||
            input.ariaLabel.includes("zip")
          ) {
            input.addEventListener("change", (event) => {
              let validationBubble =
                event.target.parentElement.lastElementChild;

              if (event.target.value !== "") {
                if (validateZipCode(event.target.value, country)) {
                  event.target.style.border = `2px solid ${fullConfig.theme.colors["mattamy-blue"]}`;
                  event.target.style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
                  event.target.classList.remove("placeholder_error");
                  event.target.classList.add("placeholder_mattamy_blue");
                  validationBubble.classList.add("hidden");
                } else {
                  validationBubble.textContent =
                    "Invalid " +
                    //(country === "USA" ? "US" : "CAN") +
                    " postal Code";
                  event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                  event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                  validationBubble.classList.remove("hidden");
                  event.target.classList.remove("placeholder_mattamy_blue");
                  event.target.classList.add("placeholder_error");
                }
              }
            });
          }
        }

        const ele1 = document.getElementById(
          "4C7B78353E484C9D82C76C5B4223F2BB"
        );
        const ele2 = document.getElementById(
          "A320E49B76054C9992E2AA7CC910B293"
        );
        const ele3 = document.getElementById(
          "47122877C66C4F4BAD1940ADB18E449E"
        );
        const ele4 = document.getElementById(
          "0DEDE45BF0C5406B945E5DA70B92A862"
        );
        const field1 = document.getElementById(
          "8803F1B2EE3A4E3EBAC6510D051C3C65_amount"
        );
        const field2 = document.getElementById(
          "7773D9DB945E472082FDB2436891E850_currency"
        );
        const field3 = document.getElementById(
          "87766C88ADC648819189FD11FD389C4B_date"
        );
        const existingGroupDiv = document.querySelector(
          "#groupBox1 div[role='group']"
        );
        const existingGroupDivField = document.querySelector(
          "#fieldBox1 div[role='group']"
        );
        if (!existingGroupDiv) {
          if (ele1 || ele2 || ele3 || ele4) {
            if (ele1) document.getElementById("groupBox1").appendChild(ele1);
            if (ele2) document.getElementById("groupBox1").appendChild(ele2);
            if (ele3) document.getElementById("groupBox1").appendChild(ele3);
            if (ele4) document.getElementById("groupBox1").appendChild(ele4);
          }
        }
        if (!existingGroupDivField) {
          if (field1 || field2 || field3) {
            if (field1)
              document.getElementById("fieldBox1").appendChild(field1);
            if (field2)
              document.getElementById("fieldBox1").appendChild(field2);
            if (field3)
              document.getElementById("fieldBox1").appendChild(field3);
          }
        }

        if (isFieldRequired) {
          input.setAttribute("aria-label", title + " field is required");
          input.setAttribute("required", true);
          if (title == "Email address") {
            input.setAttribute("autocomplete", "on");
          }

          input.addEventListener("invalid", function (event) {
            const emailAutoComplete =
              "Error, Email addresses must contain a username, ‘@’, and ‘.com’";
            input.setAttribute(
              "aria-label",
              title === "Email address"
                ? input.value === ""
                  ? "Error, " + title + " Field is Required"
                  : emailAutoComplete
                : "Error, " + title + " Field is Required"
            );

            event.preventDefault();
            let validationBubble;
            if (this.type === "checkbox") {
              validationBubble = this.parentElement.parentElement
                .lastElementChild;
              if (!this.checked) {
                validationBubble.classList.remove("hidden");
              } else validationBubble.classList.add("hidden");
            } else {
              validationBubble = this.parentElement.lastElementChild;
              if (this.value === "") {
                validationBubble.classList.remove("hidden");
              } else {
                if (this.type === "email" && !validateEmail(this.value)) {
                  validationBubble.classList.remove("hidden");

                  validationBubble.textContent =
                    "Error, Email addresses must contain a username, ‘@’, and ‘.com’";
                  event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                  event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                  event.target.classList.add("placeholder_error");
                  event.target.classList.remove("placeholder_mattamy_blue");
                } else {
                  if (this.type === "tel" && !validateTel(this.value)) {
                    validationBubble.classList.remove("hidden");
                    validationBubble.textContent =
                      "Error, Phone number must be 10 digits.";
                    event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                    event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                    event.target.classList.add("placeholder_error");
                    event.target.classList.remove("placeholder_mattamy_blue");
                  } else {
                    if (
                      (this.ariaLabel.includes("Zip") ||
                        this.ariaLabel.includes("zip")) &&
                      !validateZipCode(this.value, country)
                    ) {
                      validationBubble.classList.remove("hidden");
                      validationBubble.textContent =
                        "Invalid " +
                        //(country === "USA" ? "US" : "CAN") +
                        " postal Code";
                      event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                      event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                      event.target.classList.add("placeholder_error");
                      event.target.classList.remove("placeholder_mattamy_blue");
                    } else {
                      validationBubble.classList.add("hidden");
                      event.target.style.border = `2px solid ${fullConfig.theme.colors["mattamy-blue"]}`;
                      event.target.style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
                      event.target.classList.remove("placeholder_error");
                      event.target.classList.add("placeholder_mattamy_blue");
                    }
                  }
                }
              }
            }
          });

          input.addEventListener("change", (event) => {
            let validationBubble;
            if (event.target.type === "checkbox")
              validationBubble =
                event.target.parentElement.parentElement.lastElementChild;
            else validationBubble = event.target.parentElement.lastElementChild;

            if (event.target.value !== "") {
              input.setAttribute("aria-label", title + " field is required");

              event.target.style.border = `2px solid ${fullConfig.theme.colors["mattamy-blue"]}`;
              event.target.style.color =
                fullConfig.theme.colors["mattamy-blue"];
              if (event.target.tagName === "SELECT") {
                event.target.style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
                event.target.parentElement.parentElement.lastElementChild.children[0].style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
              }
              event.target.classList.remove("placeholder_error");
              event.target.classList.add("placeholder_mattamy_blue");
              if (
                event.target.type === "email" &&
                !validateEmail(event.target.value)
              ) {
                validationBubble.textContent =
                  "Error, Email addresses must contain a username, ‘@’, and ‘.com’";
                validationBubble.classList.remove("hidden");
                event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                event.target.classList.add("placeholder_error");
                event.target.classList.remove("placeholder_mattamy_blue");
                input.setAttribute("aria-live", "polite");
              } else {
                if (
                  event.target.type === "tel" &&
                  !validateTel(event.target.value)
                ) {
                  validationBubble.textContent =
                    "Phone number must be 10 digits";
                  validationBubble.classList.remove("hidden");
                  event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                  event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                  event.target.classList.add("placeholder_error");
                  event.target.classList.remove("placeholder_mattamy_blue");
                } else {
                  if (
                    (event.target.ariaLabel.includes("Zip") ||
                      event.target.ariaLabel.includes("zip")) &&
                    !validateZipCode(event.target.value, country)
                  ) {
                    validationBubble.textContent =
                      "Invalid " +
                      //(country === "USA" ? "US" : "CAN") +
                      " postal Code";
                    validationBubble.classList.remove("hidden");
                    event.target.style.border = `2px solid ${fullConfig.theme.colors["field-error"]}`;
                    event.target.style.color = `${fullConfig.theme.colors["field-error"]}`;
                    event.target.classList.add("placeholder_error");
                    event.target.classList.remove("placeholder_mattamy_blue");
                  } else validationBubble.classList.add("hidden");
                }
              }
            } else {
              validationBubble.classList.remove("hidden");
            }
          });
        }
        if (
          !(
            input.type === "text" &&
            (input.ariaLabel.includes("Zip") || input.ariaLabel.includes("zip"))
          )
        ) {
          input.addEventListener("change", (event) => {
            let validationBubble = event.target.parentElement.lastElementChild;
            if (event.target.value !== "") {
              event.target.style.border = `2px solid ${fullConfig.theme.colors["mattamy-blue"]}`;
              event.target.style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
              event.target.classList.remove("placeholder_error");
              event.target.classList.add("placeholder_mattamy_blue");

              if (event.target.type !== "checkbox") {
                validationBubble.classList.add("hidden");
              }

              if (event.target.tagName === "SELECT") {
                event.target.parentElement.parentElement.lastElementChild.children[0].style.color = `${fullConfig.theme.colors["mattamy-blue"]}`;
              }
              if (
                event.target.type === "email" &&
                !validateEmail(event.target.value)
              ) {
                validationBubble.textContent =
                  "Error, Email addresses must contain a username, ‘@’, and ‘.com’";
                event.target.dispatchEvent(new Event("invalid"));
                validationBubble.classList.remove("hidden");
                event.target.classList.add("placeholder_error");
                event.target.classList.remove("placeholder_mattamy_blue");
              }
            } else {
              validationBubble.classList.remove("hidden");
              event.target.classList.remove("placeholder_error");
              event.target.classList.add("placeholder_mattamy_blue");
            }
          });
        }
      }
    }

    if (isFieldByName(props, "ContextID")) {
      props.onChange(props.field.valueField.name, ContextID.value, true, null);
    }
  }, [elementRef, country]);

  useEffect(() => {
    if (hasConditions) {
      const conditions =
        props.field.model.conditionSettings.fieldConditions[0].conditions;
      const actions =
        props.field.model.conditionSettings.fieldConditions[0].actions;
      const matchType =
        props.field.model.conditionSettings.fieldConditions[0].matchTypeId;

      let flag = validateConditions(conditions, matchType);
      reviewActions(actions, flag);
    }
  });

  if (isFieldByName(props, "disclaimer")) {
    return <p className="disclaimer">{props.field.model.text}</p>;
  }

  if (isFieldByName(props, "title1") || isFieldByName(props, "title2")) {
    return (
      <div>
        <div>
          <legend
            id={props.field.model.name}
            className="font-trade-gothic-20 text-4xl text-mattamy-blue mt-4"
          >
            {props.field.model.text}
          </legend>
        </div>
        <div
          role="group"
          id={isFieldByName(props, "title1") ? "groupBox1" : "groupBox2"}
        ></div>
        <div
          role="group"
          id={isFieldByName(props, "title1") ? "fieldBox1" : "groupBox2"}
        ></div>
      </div>
    );
  }

  if (isFieldByName(props, "requiredFields")) {
    return (
      <h6 className="text-normal mb-10-mattamy font-graphie text-mattamy-blue leading-relaxed">
        {props.field.model.text}
      </h6>
    );
  }

  if (
    isFieldByName(props, "elqFormName") ||
    isFieldByName(props, "title") ||
    isFieldByName(props, "subtitle") ||
    isFieldByName(props, "ReCaptcha")
  ) {
    return null;
  }

  if (fieldTypeItemId === FieldTypes.Checkbox) {
    const child = props.children;
    return (
      <CheckboxContainer
        error={props.errors && props.errors[0]}
        id={fieldConditionKey}
        ref={elementRef}
        className="w-full text-mattamy-blue my-4 flex content-start"
      >
        {child} {isFieldRequired && !title.includes("*") ? "*" : ""}
        <ValidationBubble className="hidden mt-8 -ml-5">
          {"Error, " + title + " field is required"}
        </ValidationBubble>
      </CheckboxContainer>
    );
  }
  //

  if (fieldTypeItemId === FieldTypes.Button) {
    return (
      <ButtonContainer
        isMobile={isMobile}
        className="w-full right-0 bottom-0 mb-2 mt-10 lg:mt-4"
        ref={elementRef}
      >
        {props.children}
      </ButtonContainer>
    );
  }

  const isAmountField = isFieldByName(props, "amount");
  const isCurrenyField = isFieldByName(props, "currency");

  let width = "100%";
  if (isAmountField) {
    width = "70%";
  } else if (isCurrenyField) {
    width = "30%";
  }
  return (
    <InputContainer
      className={cx(props.field.model.cssClass, {
        "md:pr-10": isAmountField,
      })}
      onFocus={(e) => e.target.removeAttribute("aria-live")}
      empty={isEmpty(props.value)}
      title={title}
      error={props.errors && props.errors[0]}
      ref={elementRef}
      fieldTypeItemId={fieldTypeItemId}
      inline={isAmountField || isCurrenyField}
      width={width}
      id={`${fieldConditionKey}_${name}`}
    >
      <div className="flex flex-col w-full">
        <div className="text-mattamy-blue font-bold my-2">
          {title + (isFieldRequired && !title.includes("*") ? " *" : "")}
        </div>

        {props.children}
        <ValidationBubble
          className={cx("hidden ml-32", {
            "mt-24": fieldTypeItemId !== FieldTypes.MultipleLineText,
          })}
          isMultipleLineText={fieldTypeItemId === FieldTypes.MultipleLineText}
        >
          {"Error, " + title + " field is required"}
        </ValidationBubble>
      </div>

      {props.field.model.items && (
        <IconContainer className="-mr-2 pt-8 mt-1 h-full">
          <ChevronDownIcon className={"w-4 text-mattamy-blue"} />
        </IconContainer>
      )}
    </InputContainer>
  );
};

export default InvestorRelationsField;
