const USA_COUNTRY_CODE = "US";
const CAN_COUNTRY_CODE = "CA";

export const isValidCountry = (countryCode) =>
  countryCode && [USA_COUNTRY_CODE, CAN_COUNTRY_CODE].includes(countryCode);

export const getCountryAppCode = (countryCode) => {
  if (countryCode === CAN_COUNTRY_CODE) return "CAN";
  if (countryCode === USA_COUNTRY_CODE) return "USA";
  return "";
};

const getAddress = (latitude, longitude) =>
  fetch(`https://geocode.xyz/${latitude},${longitude}?geoit=json`).then((res) =>
    res.json()
  );

const ipLookUp = () =>
  fetch("http://ip-api.com/json").then(
    function success(response) {
      return response.json();
    },
    function fail(data, status) {
      return status;
    }
  );

export const getLocation = () =>
  new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function success(position) {
          getAddress(position.coords.latitude, position.coords.longitude)
            .then((data) => {
              resolve({
                country: data.country,
                countryCode: data.prov,
                lat: data.latt,
                lng: data.longt,
              });
            })
            .catch((error) => {
              reject(error);
            });
        },
        function error() {
          ipLookUp()
            .then((data) => {
              resolve({ ...data, lng: data.lon });
            })
            .catch((error) => reject(error));
        }
      );
    } else {
      ipLookUp()
        .then((data) => {
          resolve({ ...data, lng: data.lon });
        })
        .catch((error) => reject(error));
    }
  });
